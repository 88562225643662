import React from "react";
import { NavLink } from "react-router-dom";
const GeneratorsList = () => {

  return (
    <>
      <li>
        <NavLink to="/tools/contract-policy">Contract Policy Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/qr-code-generator">QR Code Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/password-generator">Password Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/gradient-generator">Gradient Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/text-generator">Text Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/html-color-generator">HTML Color Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/color-mixer">Color Mixer</NavLink>
      </li>

      <li>
        <NavLink to="/tools/number-generator">Number Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/privacy-generator"> Privacy Generator</NavLink>
      </li>
    </>
  );
};

export default GeneratorsList;
