import React from "react";
const LoadingSpinner = () => {
  return (
    <>
      <div className="loading ">
        <span className="loader"></span>
      </div>
    </>
  );
};

export default LoadingSpinner;
