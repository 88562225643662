import { createSlice } from "@reduxjs/toolkit";

import { getAllUsers, createNewUser, deleteUser, onUpdateUser } from "./thunk";
export const initialState = {
  user: {},
  isLoading: false,
  isUserSuccess: false,
  error: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,

  extraReducers: (builder) => {
    builder
 
      .addCase(createNewUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        if (action.payload && action.payload.user) {
          state.users = [...state.users, action.payload?.user];
        }
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = state.users.filter(
          (user) => user.id.toString() !== action.payload?.toString()
        );
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateUser.fulfilled, (state, action) => {
        // const { obj } = action.payload;
       
        state.isLoading = false;
        state.user = action.payload
      })
      .addCase(onUpdateUser.rejected, (state, action) => {
        state.isLoading = false;
      });
      
  },
});

export default userSlice.reducer;
