import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  Col,
  Row,
  Input,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Modal,
  Form,
  FormFeedback,
} from "reactstrap";
import { userimg } from "../../images";

const UpdateProfileModal = ({ modal, toggle, validation, setModal }) => {
  const [imageOne, setImageOne] = useState("");
  const handleImageOneChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 100 * 1024) {
      const imageURL = URL.createObjectURL(file);

      setImageOne(imageURL);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageString = event.target.result;
        validation.setFieldValue("userImg", base64ImageString);
        validation.setFieldError("userImg", ""); // Clear any previous error
      };
      reader.readAsDataURL(file);
    } else {
      validation.setFieldError(
        "userImg",
        "File size should be less than or equal to 70 KB"
      );
    }
  };

 
  return (
    <>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {"Edit User"}
        </ModalHeader>
        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Input type="hidden" id="id-field" />
            <Row className="g-3">
              <Col lg={12}>
                <div className="text-center">
                  <div className="position-relative d-inline-block">
                    <div className="position-absolute bottom-0 end-0">
                      <Label htmlFor="lead-image-input" className="mb-0">
                        <div className="avatar-xs cursor-pointer">
                          <div className="avatar-title bg-light border rounded-circle text-muted">
                            <i className="ri-image-fill"></i>
                          </div>
                        </div>
                      </Label>
                      <Input
                        name="userImg"
                        className="form-control d-none"
                        id="lead-image-input"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleImageOneChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.userImg &&
                          validation.errors.userImg
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="avatar-lg p-1">
                      <div className="avatar-title bg-light rounded-circle">
                        <img
                          src={
                            validation.values.userImg === "1"
                              ? userimg // Display dummy image when userImg is "1"
                              : validation.values.userImg || imageOne // Display selected image or default image
                          }
                          alt=" "
                          id="lead-img"
                          className="rounded-circle avatar-md img-thumbnail user-profile-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-danger fs-10">
                    {validation.errors.userImg && ( // Check if there's an error for imageOne
                      <div className="text-danger">
                        {validation.errors.userImg}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Label htmlFor="name-field" className="form-label">
                    Full Name
                  </Label>
                  <Input
                    name="fullName"
                    id="customername-field"
                    className="form-control"
                    placeholder="Enter Full Name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fullName || ""}
                    invalid={
                      validation.touched.fullName && validation.errors.fullName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.fullName && validation.errors.fullName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.fullName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label htmlFor="company_name-field" className="form-label">
                    Email
                  </Label>
                  <Input
                    name="email"
                    id="company_name-field"
                    className="form-control"
                    placeholder="Enter Email"
                    type="text"
                    disabled
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label htmlFor="bio-field" className="form-label">
                    Bio
                  </Label>
                  <textarea
                    name="description"
                    id="bio-field"
                    className={`form-control ${
                      validation.touched.description &&
                      validation.errors.description
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter bio"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    rows="4" // Define the number of visible rows
                  />
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="url-field" className="form-label">
                    Github URL
                  </Label>
                  <input
                    type="url"
                    name="githubUrl"
                    id="url-field"
                    className={`form-control ${
                      validation.touched.githubUrl &&
                      validation.errors.githubUrl
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter URL"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.githubUrl || ""}
                  />
                  {validation.touched.githubUrl &&
                  validation.errors.githubUrl ? (
                    <FormFeedback type="invalid">
                      {validation.errors.githubUrl}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor="url-field" className="form-label">
                    Linkdin URL
                  </Label>
                  <input
                    type="url"
                    name="linkedinUrl"
                    id="url-field"
                    className={`form-control ${
                      validation.touched.linkedinUrl &&
                      validation.errors.linkedinUrl
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter URL"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.linkedinUrl || ""}
                  />
                  {validation.touched.linkedinUrl &&
                  validation.errors.linkedinUrl ? (
                    <FormFeedback type="invalid">
                      {validation.errors.linkedinUrl}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {"Update"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateProfileModal;
