import React from "react";
import { Link, NavLink } from "react-router-dom";

const Service = () => {
  return (
    <>
      {/* Design */}
      <li>
        <NavLink to="/services/web-design">Web Design</NavLink>
      </li>

      {/* Frontend */}
      <li>
        <NavLink to="/services/frontend-development">Frontend Development</NavLink>
      </li>

      {/* Digital Marketing */}
      <li>
        <NavLink to="/services/digital-marketing">Digital Marketing</NavLink>
      </li>

      {/*Web Development*/}
      <li>
        <NavLink to="/services/web-development">Web Development</NavLink>
      </li>
      {/*2D Animations*/}
      <li>
        <NavLink to="/services/2d-animations">2D Animations</NavLink>
      </li>
    </>
  );
};

export default Service;
