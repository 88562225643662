import React, { useState } from "react";
// import AnimatePage from "../../components/AnimatePage";
import classnames from "classnames";
import { Col, Collapse, Row } from "reactstrap";

const GuestPostFaqs = () => {
  const [col1, setcol1] = useState(false);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col_01, setcol_01] = useState(false);
  const [col_02, setcol_02] = useState(false);
  const [col_03, setcol_03] = useState(false);
  const [col_04, setcol_04] = useState(false);
  const [col_001, setCol_001] = useState(false);

  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col_05, setcol_05] = useState(false);
  const [col_06, setcol_06] = useState(false);
  const [col_07, setcol_07] = useState(false);
  const [col_08, setcol_08] = useState(false);
  const [col_005, setcol_005] = useState(false);

  // LEFT SECTION
  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(false);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(false);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol4(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(false);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(false);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col01 = () => {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol_01(!col_01);
    setcol_02(false);
    setcol_03(false);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col02 = () => {
    setcol2(false);
    setcol1(false);
    setcol3(false);
    setcol4(false);
    setcol_01(false);
    setcol_02(!col_02);
    setcol_03(false);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col03 = () => {
    setcol3(false);
    setcol1(false);
    setcol2(false);
    setcol4(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(!col_03);
    setcol_04(false);
    setCol_001(false);
  };

  const t_col04 = () => {
    setcol4(false);
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(false);
    setcol_04(!col_04);
    setCol_001(false);
  };

  const t_col001 = () => {
    setcol4(false);
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol_01(false);
    setcol_02(false);
    setcol_03(false);
    setcol_04(false);
    setCol_001(!col_001);
  };

  // RIGHT SECTION
  const t_col5 = () => {
    setcol5(!col5);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(false);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col6 = () => {
    setcol6(!col6);
    setcol5(false);
    setcol7(false);
    setcol8(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(false);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col7 = () => {
    setcol7(!col7);
    setcol6(false);
    setcol5(false);
    setcol8(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(false);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col8 = () => {
    setcol8(!col8);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(false);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col05 = () => {
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol_05(!col_05);
    setcol_06(false);
    setcol_07(false);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col06 = () => {
    setcol6(false);
    setcol5(false);
    setcol7(false);
    setcol8(false);
    setcol_05(false);
    setcol_06(!col_06);
    setcol_07(false);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col07 = () => {
    setcol7(false);
    setcol6(false);
    setcol5(false);
    setcol8(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(!col_07);
    setcol_08(false);
    setcol_005(false);
  };

  const t_col08 = () => {
    setcol8(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(false);
    setcol_08(!col_08);
    setcol_005(false);
  };

  const t_col005 = () => {
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol_05(false);
    setcol_06(false);
    setcol_07(false);
    setcol_08(false);
    setcol_005(!col_005);
  };

  const items = [
    "technology + “write for us UK” + guest post",
    "Hosting + “write for us UK” + guest post",
    "fashion write for us UK",
    "online shopping write for us guest post",
    "“write for us” technology",
    "online marketing + “write for us”",
    "web development “write for us”",
    "business write for us",
    "“write for us” digital marketing",
    "business “write for us UK”",
    "write for us UK",
    "fashion blog write for us",
    "software testing “write for us”",
    "Business + “write for us” + guest post",
    "Hosting + “writeforus” + guest post",
    "tech blogs “write for us”",
    "technology write for us guest post",
    "online marketing + “writeforus”",
    "social media marketing “writeforus”",
    "SEO “write for us”",
    "write to us free",
    "technology blog write for us",
    "Travel “write for us” + guest post",
    "Health “write for us” + guest post",
    "Fashion “write for us” + guest post",
    "Pats “writeforus” + guest post",
    "lifestyle “write for us” + guest post",
  ];

  const halfLength = Math.ceil(items.length / 2);
  const leftColumnItems = items.slice(0, halfLength);
  const rightColumnItems = items.slice(halfLength);

  return (
    <>
      <Row className="justify-content-evenly">
        <Col lg={6} className="">
          <div className="">
            <div
              className="accordion accordion-border-box"
              id="genques-accordion"
            >
              {/* /// left part 1 */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col1,
                    })}
                    type="button"
                    onClick={t_col1}
                    style={{ cursor: "pointer" }}
                  >
                    What we’re looking for?
                  </button>
                </h2>
                <Collapse isOpen={col1} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      Please feel free to submit an outline along with a rough
                      draft, partial manuscript, or a brief pitch (one or two
                      paragraphs summarizing your argument and explaining its
                      importance to our readers). The more complete your
                      submission, the more constructive feedback we can provide.
                      Please be aware that we only accept unique and original
                      content. Your content will not be published if it is
                      copied or has been published elsewhere.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col2,
                    })}
                    type="button"
                    onClick={t_col2}
                    style={{ cursor: "pointer" }}
                  >
                    What We Don't Publish on Softorithm
                  </button>
                </h2>
                <Collapse isOpen={col2} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      <strong>Too much Promotion</strong>: While we appreciate
                      showcasing expertise, Softorithm is not a platform for
                      overtly promoting services, offers, or companies. Our
                      readers are here for successful stories, tips, and advice
                      on how to start and run a successful startup.
                      <br />
                      <strong>Already Published Content</strong>: We do not
                      publish articles that have already been featured on our
                      website, even with modifications. We exclusively accept
                      unique and original content.
                      <br />
                      <strong>Copied Content</strong>: Copied or plagiarized
                      content is strictly against our policy. Such violations
                      can lead to permanent deactivation and banning of your
                      account.
                      <br />
                      <strong>Misleading Information</strong>: We do not accept
                      content with fake information or data. Ensure proper
                      citations, credits, and quoting of sources. Always use
                      reliable information from authoritative sites and give
                      credit accordingly.
                      <br />
                      <strong>Use of Pics/Images</strong>: Please refrain from
                      adding any pictures or images to your content before
                      submission. Due to copyright issues, we do not allow it.
                      If necessary, our team will handle image inclusion.
                      <br />
                      <strong>Individual Profiles</strong>: We generally do not
                      accept individual profiles of startup founders unless they
                      are well-recognized and household names in the industry.
                      By adhering to these guidelines, you contribute to
                      maintaining the integrity and quality of content on
                      Softorithm. We appreciate your understanding and look
                      <br />
                      forward to receiving valuable contributions that align
                      with our readers' interests.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col3,
                    })}
                    type="button"
                    onClick={t_col3}
                    style={{ cursor: "pointer" }}
                  >
                    What types of people can write guest posts for Softorithm
                    website?
                  </button>
                </h2>
                <Collapse isOpen={col3} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      <strong>Bloggers and Writers</strong>: Individuals who
                      maintain personal blogs or regularly contribute content
                      can use guest posting to reach a wider audience.
                      <br />
                      <strong>Industry Experts</strong>: Professionals with
                      expertise in a particular field can share their knowledge
                      and insights through guest posts, establishing themselves
                      as thought leaders.
                      <br />
                      <strong>Freelance Writers</strong>: Freelancers looking to
                      showcase their writing skills and build a portfolio can
                      benefit from guest posting on reputable websites.
                      <br />
                      <strong>Entrepreneurs and Business Owners</strong>: Those
                      who run businesses or startups can use guest posting to
                      increase brand visibility, share success stories, and
                      promote their products or services.
                      <br />
                      <strong>Marketers</strong>: Marketing professionals can
                      leverage guest posting to build backlinks, drive traffic,
                      and improve search engine rankings for their websites.
                      <br />
                      <strong>Authors</strong>: Writers promoting their books or
                      looking to establish themselves in a niche can use guest
                      posting to connect with their target audience.
                      <br />
                      <strong>Students and Researchers</strong>: Individuals
                      pursuing academic or research interests can contribute
                      articles to relevant websites to share their findings and
                      gain exposure.
                      <br />
                      <strong>Public Speakers</strong>: Those who speak at
                      conferences or events can use guest posting to extend the
                      reach of their message and connect with a broader
                      audience.
                      <br />
                      <strong>Content Creators</strong>: Video creators,
                      podcasters, and other content creators can use guest
                      posting to complement their multimedia efforts and engage
                      with a diverse audience.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingFour">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col4,
                    })}
                    type="button"
                    onClick={t_col4}
                    style={{ cursor: "pointer" }}
                  >
                    How to increase the chances of getting published on our blog
                  </button>
                </h2>
                <Collapse isOpen={col4} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      To enhance the likelihood of your guest post being
                      accepted on our blog, make sure your contribution is
                      comprehensive, data-driven, engaging, and informative.
                      Here are the criteria for a successful submission:
                    </p>
                    <ul style={{ listStyle: "unset" }}>
                      <li>
                        Craft a relevant, well-researched post (preferably
                        exceeding 1000 words) that offers actionable tips.
                      </li>
                      <li>
                        Ensure your content is entirely original and has not
                        been published elsewhere; we do not republish previously
                        published material.
                      </li>
                      <li>
                        Support claims with links to credible research or case
                        studies, avoiding references to competitors and the
                        inclusion of irrelevant promotional links.
                      </li>
                      <li>
                        Include examples and pertinent images to illustrate your
                        points. Refrain from using generic stock photos that do
                        not enhance the content; instead, utilize Creately for
                        visualizing data, information, processes, ideas, and
                        frameworks.
                      </li>
                      <li>
                        Structure your article with subheadings, bullet points,
                        and concise paragraphs to enhance readability.
                      </li>
                      <li>
                        Outgoing links must be relevant to our blog. For
                        example, linking to dating sites, SEO agencies,
                        credit-related sites, etc. isn’t allowed.
                      </li>
                      <li>
                        Once published on our blog, you cannot publish it
                        anywhere else, including your blog.
                      </li>
                      <li>
                        Images and videos are encouraged, as they make the
                        article look more enticing to the reader.
                      </li>
                      <li>
                        The image width should be 580 pixels or less. Please
                        send them as a separate attachment.
                      </li>
                      <li>
                        Make sure not to violate copyright when using images.
                        Give credit when needed.
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
              {/* /// left part 2 */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_01,
                    })}
                    type="button"
                    onClick={t_col01}
                    style={{ cursor: "pointer" }}
                  >
                    What are the editorial Policies for Guest Post Submissions?
                  </button>
                </h2>
                <Collapse isOpen={col_01} className="accordion-collapse">
                  <div className="accordion-body">
                    <div>
                      <strong>Originality and Uniqueness:</strong>
                      <ul>
                        <li>
                          All submitted content must be original and not
                          published elsewhere.
                        </li>
                        <li>
                          We do not accept content that has been spun or
                          plagiarized.
                        </li>
                      </ul>

                      <strong>Quality of Content:</strong>
                      <ul>
                        <li>
                          Content should be well-written, grammatically correct,
                          and free of spelling errors.
                        </li>
                        <li>
                          Articles must provide value to our audience and align
                          with the interests of our readers.
                        </li>
                      </ul>

                      <strong>Relevance to Audience:</strong>
                      <ul>
                        <li>
                          Submissions should be relevant to our website's theme
                          and audience.
                        </li>
                        <li>
                          Topics should align with the categories and niches
                          specified in our guidelines.
                        </li>
                      </ul>

                      <strong>Formatting and Structure:</strong>
                      <ul>
                        <li>
                          Please follow our specified formatting guidelines for
                          headings, subheadings, and paragraphs.
                        </li>
                        <li>
                          Use bullet points or numbered lists where appropriate
                          to enhance readability.
                        </li>
                      </ul>

                      <strong>Length and Depth:</strong>
                      <ul>
                        <li>
                          Aim for a reasonable word count that thoroughly covers
                          the topic.
                        </li>
                        <li>
                          Provide in-depth information and insights rather than
                          surface-level content.
                        </li>
                      </ul>

                      <strong>Non-Promotional Tone:</strong>
                      <ul>
                        <li>
                          The content should not be overly promotional or
                          self-serving.
                        </li>
                        <li>
                          Avoid excessive use of brand names unless integral to
                          the discussion.
                        </li>
                      </ul>

                      <strong>Citation and Attribution:</strong>
                      <ul>
                        <li>
                          Properly cite and attribute sources where necessary.
                        </li>
                        <li>
                          If you include statistics or data, provide the source
                          for credibility.
                        </li>
                      </ul>

                      <strong>Multimedia Elements:</strong>
                      <ul>
                        <li>
                          If applicable, include high-quality images, charts, or
                          graphs to enhance the content.
                        </li>
                        <li>
                          Ensure that you have the right to use any multimedia
                          elements submitted.
                        </li>
                      </ul>

                      <strong>Content Updates:</strong>
                      <ul>
                        <li>
                          Regularly update your content to ensure it remains
                          accurate and relevant over time.
                        </li>
                        <li>
                          Notify us if there are significant changes or updates
                          needed post-publishing.
                        </li>
                      </ul>

                      <strong>Author Bio and Links:</strong>
                      <ul>
                        <li>
                          Include a brief author bio with your submission.
                        </li>
                        <li>
                          You may include a link to your personal website or
                          social media profiles.
                        </li>
                      </ul>

                      <strong>Exclusivity:</strong>
                      <ul>
                        <li>
                          We appreciate exclusivity for a specified period after
                          publication.
                        </li>
                        <li>
                          Authors may not republish the same content on other
                          platforms during this time.
                        </li>
                      </ul>

                      <strong>Editorial Rights:</strong>
                      <ul>
                        <li>
                          We reserve the right to edit and make necessary
                          adjustments to submitted content.
                        </li>
                        <li>
                          Edits will be communicated with the author before
                          publication.
                        </li>
                      </ul>

                      <strong>Review and Approval Process:</strong>
                      <ul>
                        <li>
                          Submitted content will undergo a review process that
                          may include multiple rounds of revisions.
                        </li>
                        <li>
                          Authors will be notified of the status of their
                          submission within a specified timeframe.
                        </li>
                      </ul>

                      <strong>Removal of Content:</strong>
                      <ul>
                        <li>
                          We reserve the right to remove any content that
                          violates our editorial policies.
                        </li>
                        <li>
                          Authors may request the removal of their content,
                          subject to review.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_02,
                    })}
                    type="button"
                    onClick={t_col02}
                    style={{ cursor: "pointer" }}
                  >
                    What are the categories of Softorithm to write for us?
                  </button>
                </h2>
                <Collapse isOpen={col_02} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul>
                      <li>Technology</li>
                      <li>Shopping</li>
                      <li>Business</li>
                      <li>Health</li>
                      <li>Fashion</li>
                      <li>Travel</li>
                      <li>Lifestyle</li>
                      <li>Digital Marketing</li>
                      <li>Website</li>
                      <li>Online Marketing</li>
                      <li>Web Hosting</li>
                      <li>WordPress</li>
                      <li>SEO (Search Engine Optimization)</li>
                      <li>Gadgets</li>
                      <li>App Development</li>
                      <li>Anti-Virus</li>
                      <li>Internet Security</li>
                      <li>Making Money Online</li>
                      <li>Coupons Deals</li>
                      <li>Tips and Tricks</li>
                      <li>Games</li>
                    </ul>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_03,
                    })}
                    type="button"
                    onClick={t_col03}
                    style={{ cursor: "pointer" }}
                  >
                    What you should consider when you write for us?
                  </button>
                </h2>
                <Collapse isOpen={col_03} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      We have decided to open up guest posting opportunities to
                      provide our readers and entrepreneurs with a chance to
                      build their brand. Consequently, we prefer collaborating
                      with genuine individuals rather than SEO agencies. Before
                      submitting your article, take the time to familiarize
                      yourself with our platform. Connect with us on our social
                      media channels and share our other articles, as doing so
                      significantly enhances the likelihood of your work being
                      published.
                    </p>
                    <p>Follow us on:</p>
                    <ul className="mb-2">
                      <li>Facebook</li>
                      <li>Twitter</li>
                      <li>Pinterest</li>
                      <li>LinkedIn</li>
                    </ul>
                    <p>
                      Given the volume of submissions we receive (50-100 per
                      month), please refrain from sending emails inquiring about
                      the publication status of your article. If your article is
                      selected, you will hear from us.
                    </p>
                    <p>
                      While we acknowledge that the guidelines may seem lengthy
                      and the requirements stringent, they are in place to
                      mitigate spam submissions. We look forward to receiving
                      outstanding articles. If we find that your submission
                      aligns with our criteria, we will be in touch to discuss
                      the next steps and any additional information required to
                      publish your post.
                    </p>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingFour">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_04,
                    })}
                    type="button"
                    onClick={t_col04}
                    style={{ cursor: "pointer" }}
                  >
                    What type of credits an Author can get from our guest
                    posting website?
                  </button>
                </h2>
                <Collapse isOpen={col_04} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      Guest authors can receive various types of credits from a
                      guest posting website, and these credits are essential for
                      acknowledging and showcasing their contributions. Here are
                      the types of credits an author can typically receive:
                    </p>
                    <strong>Author Name:</strong>
                    <p>
                      Display the author's name prominently on the published
                      post to give them recognition for their work.
                    </p>
                    <strong>Author Bio:</strong>
                    <ul style={{ listStyle: "unset" }}>
                      <li>
                        Include a dedicated section for the author's biography
                        where they can provide information about themselves,
                        their expertise, and interests.
                      </li>
                      <li>
                        Profile Picture: Allow authors to include a professional
                        headshot or profile picture alongside their bio, adding
                        a personal touch to their contribution.
                      </li>
                      <li>
                        Contact Information: Offer the option for authors to
                        include their contact information, such as an email
                        address or links to their social media profiles,
                        facilitating communication with readers.
                      </li>
                      <li>
                        Website or Blog Link: Permit authors to include a link
                        to their personal website or blog, providing a valuable
                        backlink and driving traffic to their online platforms.
                      </li>
                      <li>
                        Social Media Links: Enable authors to link to their
                        relevant social media profiles, allowing them to connect
                        with readers and expand their online presence.
                      </li>
                      <li>
                        Previous Publications: Allow authors to mention or link
                        to their previous guest posts on your site or other
                        publications, showcasing their experience and expertise.
                      </li>
                      <li>
                        Professional Affiliations: Provide space for authors to
                        mention any professional affiliations, memberships, or
                        associations they are part of, adding credibility to
                        their profile.
                      </li>
                      <li>
                        Call-to-Action (CTA): Encourage authors to include a
                        call-to-action, inviting readers to connect with them,
                        follow their work, or visit their website.
                      </li>
                      <li>
                        Byline Placement: Specify where the author's byline will
                        appear on the post, ensuring clear visibility and credit
                        for their contribution.
                      </li>
                      <li>
                        Acknowledgment Email: Send a personalized acknowledgment
                        email to authors upon the publication of their post,
                        expressing gratitude and providing links to the
                        published content.
                      </li>
                      <li>
                        Author Dashboard or Profile: If your guest posting
                        platform has user profiles, create an author dashboard
                        where authors can manage and view their contributions,
                        bio, and other details.
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_001,
                    })}
                    type="button"
                    onClick={t_col001}
                    style={{ cursor: "pointer" }}
                  >
                    What is the policy of Softorithm for promotion and
                    backlinks?
                  </button>
                </h2>
                <Collapse isOpen={col_001} className="accordion-collapse">
                  <div className="accordion-body">
                    <strong>1. Non-Promotional Content:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> Guest posts should provide
                        valuable content to our audience without being overly
                        promotional.
                        <br />
                        <strong>Rationale:</strong> Our primary focus is to
                        offer informative and engaging content to our readers.
                        Excessive promotion within the content is discouraged.
                      </li>
                    </ul>
                    <strong>2. Author Bio Links:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> Authors are allowed to include
                        a link to their personal website or blog in the author
                        bio.
                        <br />
                        <strong>Rationale:</strong> We encourage authors to
                        showcase their work and provide a means for readers to
                        explore more about them.
                      </li>
                    </ul>
                    <strong>3. In-Content Links:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> In-content links to external
                        websites must be relevant and add value to the reader.
                        <br />
                        <strong>Rationale:</strong> Links within the content
                        should enhance the reader's experience by providing
                        supplementary information or resources.
                      </li>
                    </ul>
                    <strong>4. Limit on Promotional Links:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> A reasonable limit will be
                        imposed on the number of promotional links within a
                        guest post.
                        <br />
                        <strong>Rationale:</strong> This ensures that the focus
                        remains on valuable content rather than excessive
                        self-promotion.
                      </li>
                    </ul>
                    <strong>5. Affiliate Links:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> Affiliate links are not allowed
                        within guest posts.
                        <br />
                        <strong>Rationale:</strong> Our platform aims to
                        maintain transparency and trust with our audience, and
                        the inclusion of affiliate links may compromise this.
                      </li>
                    </ul>
                    <strong>6. Relevance of Links:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> All links, both in the author
                        bio and in the content, must be relevant to the topic
                        and add value to the reader.
                        <br />
                        <strong>Rationale:</strong> This helps maintain the
                        integrity of the content and ensures that readers find
                        the links useful.
                      </li>
                    </ul>
                    <strong>7. Nofollow Attribute:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> All external links will be set
                        to a "nofollow" attribute. (you can send us dofollow
                        baclink request)
                        <br />
                        <strong>Rationale:</strong> This practice aligns with
                        search engine guidelines and prevents the passing of
                        link equity to external sites.
                      </li>
                    </ul>
                    <strong>8. Link Removal Requests:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> Authors may request link
                        removal or updates to their content after publication,
                        subject to review.
                        <br />
                        <strong>Rationale:</strong> We understand that
                        circumstances may change, and authors should have the
                        opportunity to manage their online presence.
                      </li>
                    </ul>
                    <strong>9. Content Updates:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> Authors are encouraged to
                        update their content regularly to keep information
                        accurate and relevant.
                        <br />
                        <strong>Rationale:</strong> This benefits both the
                        author and our readers by ensuring the content remains
                        valuable over time.
                      </li>
                    </ul>
                    <strong>10. Violation Consequences:</strong>
                    <ul>
                      <li>
                        <strong>Policy:</strong> Violation of the promotion and
                        backlinks policy may result in the removal of content or
                        a temporary ban from guest posting.
                        <br />
                        <strong>Rationale:</strong> These measures are in place
                        to maintain the quality and integrity of our platform.
                      </li>
                    </ul>
                    Authors are encouraged to review and adhere to these
                    policies when submitting guest posts. Regular updates to
                    these guidelines may be implemented to align with industry
                    best practices and platform requirements.
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={6} className="">
          <div className="">
            <div
              className="accordion accordion-border-box"
              id="manageaccount-accordion"
            >
              {/* RIGHT SECTION PART 1 */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col5,
                    })}
                    type="button"
                    onClick={t_col5}
                    style={{ cursor: "pointer" }}
                  >
                    Where you can add your links to the article?
                  </button>
                </h2>
                <Collapse isOpen={col5} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      We present two locations where you can incorporate your
                      keyword strategically: Content Body: Embed your links
                      within the various segments of the content body. Ensure
                      that the specific keyword aligns with the topic on which
                      you intend to post. Author Bio: Another viable option is
                      the author bio, which serves as an additional space for
                      incorporating keywords about your website and social media
                      sources.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col6,
                    })}
                    type="button"
                    onClick={t_col6}
                    style={{ cursor: "pointer" }}
                  >
                    How to find us for Sharing your Articles & Guest Post
                  </button>
                </h2>
                <Collapse isOpen={col6} className="accordion-collapse">
                  <div className="accordion-body">
                    <Row>
                      <Col>
                        <ul>
                          {leftColumnItems.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </Col>
                      <Col>
                        <ul>
                          {rightColumnItems.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="manageaccount-headingThree"
                >
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col7,
                    })}
                    type="button"
                    onClick={t_col7}
                    style={{ cursor: "pointer" }}
                  >
                    What are the Rights and Ownership Policy for Guest Posts on
                    Softorithm?
                  </button>
                </h2>
                <Collapse isOpen={col7} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <strong>Author Copyright Authority:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm respects the
                            intellectual property rights of our contributing
                            authors. Authors retain full copyright authority to
                            their submitted content.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Empowering authors to
                            maintain control over their creative work and
                            intellectual property.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Limited Author Copyright Authority:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> After the content is
                            published on Softorithm's website authors may not
                            publish the same content on any other website.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Ensuring the uniqueness
                            and freshness of content on Softorithm's platform.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Permanent Archival Rights:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm retains
                            permanent archival rights to all published content.
                            This includes the right to store, display, and
                            provide access to the content on Softorithm's
                            website indefinitely.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Maintaining a
                            comprehensive and accessible repository of valuable
                            content for users.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Changes and Edits:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm reserves the
                            right to make necessary edits and changes to
                            submitted content for clarity, style, or formatting
                            while preserving the core message and intent of the
                            author.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Ensuring content adheres
                            to editorial standards and aligns with the
                            platform's guidelines.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Removal of Content:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Authors cannot request the
                            removal of their content from Softorithm after
                            publication.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Respecting the author's
                            right to control the presence of their work on the
                            platform.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Unpublished Drafts:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Unpublished drafts
                            submitted by authors but not selected for
                            publication remain the property of the author.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Respecting the creative
                            work of authors, even if it is not featured on the
                            platform.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Shared Content Usage:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm may share links
                            to and excerpts from the published content on social
                            media and other promotional channels for marketing
                            and promotional purposes.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Aiding in the promotion
                            of both the author and Softorithm's platform.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Notification of Changes:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Authors will not be
                            notified of any significant changes to the Rights
                            and Ownership policy.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Ensuring transparency
                            and providing authors with awareness of policy
                            modifications.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingFour">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col8,
                    })}
                    type="button"
                    onClick={t_col8}
                    style={{ cursor: "pointer" }}
                  >
                    Why is guest posting important for your business?
                  </button>
                </h2>
                <Collapse isOpen={col8} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      Guest posting, commonly known as "write for us," stands
                      out as one of the most widely employed SEO methods,
                      consistently utilized by organizations over an extended
                      period. This approach yields numerous advantages for
                      businesses of all types, providing a platform to showcase
                      expertise and valuable insights, thereby solidifying a
                      strong presence in the marketplace. It plays a pivotal
                      role in establishing market connections and expanding your
                      audience. Furthermore, guest posting serves as a valuable
                      platform for aspiring authors or emerging companies,
                      offering the opportunity to extend and strengthen their
                      foothold within the market.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_05,
                    })}
                    type="button"
                    onClick={t_col05}
                    style={{ cursor: "pointer" }}
                  >
                    What Are the Advantages of Guest Posting or "Write for Us"?
                  </button>
                </h2>
                <Collapse isOpen={col_05} className="accordion-collapse">
                  <div className="accordion-body">
                    <p>
                      Guest posting, in simple terms, involves contributing
                      written content to other company websites. Many websites
                      actively invite and facilitate authors to submit guest
                      posts, often providing a dedicated page labeled "Write for
                      Us." Guest bloggers typically explore websites within the
                      same industry or topic to gather information.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_06,
                    })}
                    type="button"
                    onClick={t_col06}
                    style={{ cursor: "pointer" }}
                  >
                    What are the benefits of Guest Posting on Softorithm?
                  </button>
                </h2>
                <Collapse isOpen={col_06} className="accordion-collapse">
                  <div className="accordion-body">
                    <Row>
                      <Col>
                        <ul>
                          <li>
                            Increased Website Traffic: Guest posting contributes
                            to a higher influx of website visitors.
                          </li>
                          <li>
                            Improved Domain Authority: It enhances the domain
                            authority of the guest writer's website.
                          </li>
                          <li>
                            Elevated Brand Awareness: Guest posting leads to
                            heightened visibility and awareness for the brand.
                          </li>
                          <li>
                            Link Building: It facilitates the establishment of
                            links within the same industry, contributing to a
                            network of interconnected businesses.
                          </li>
                        </ul>
                        <p>
                          It is essential to recognize that guest blogging,
                          "write for us" initiatives, or sponsored content not
                          only benefit the contributor but also add value to the
                          hosting website.
                        </p>
                      </Col>
                      <Col>
                        <ul>
                          {rightColumnItems.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="manageaccount-headingThree"
                >
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_07,
                    })}
                    type="button"
                    onClick={t_col07}
                    style={{ cursor: "pointer" }}
                  >
                    What is the Technical Assistance Policy of Softorithm?
                  </button>
                </h2>
                <Collapse isOpen={col_07} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <strong>Submission Process Assistance:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm is committed to
                            providing technical assistance during the guest post
                            submission process. If authors encounter any issues
                            or have questions, our support team is available to
                            assist promptly.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Ensuring a seamless
                            submission process and addressing any technical
                            queries authors may have.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Formatting Support:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Authors can reach out for
                            assistance with formatting their content. Our team
                            will provide guidance on proper headings,
                            subheadings, and other formatting elements.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Facilitating authors in
                            presenting their content in a visually appealing and
                            reader-friendly manner.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Technical Issue Resolution:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> In case of technical issues
                            on the platform during submission, authors can
                            contact our technical support team for prompt
                            resolution.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Ensuring authors have a
                            smooth and trouble-free experience on our platform.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Communication Channels:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Authors can utilize
                            designated communication channels (email, support
                            tickets, etc.) for technical assistance.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Streamlining
                            communication to efficiently address technical
                            concerns.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Notification of Updates:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Authors will be notified of
                            any updates or changes to the submission process
                            that may impact the technical aspects of guest post
                            submissions.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Keeping authors informed
                            of any changes that may affect their interaction
                            with the platform.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingFour">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_08,
                    })}
                    type="button"
                    onClick={t_col08}
                    style={{ cursor: "pointer" }}
                  >
                    What is the Community Guidelines Policy of Softorithm?
                  </button>
                </h2>
                <Collapse isOpen={col_08} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <strong>Respectful and Inclusive Communication:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm encourages a
                            community built on respectful and inclusive
                            communication. Authors and readers are expected to
                            engage in discussions with courtesy and respect.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Fostering a positive and
                            collaborative community environment.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Constructive Criticism:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Constructive criticism is
                            welcomed, provided it is respectful and contributes
                            to the improvement of content and ideas.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Encouraging feedback
                            that helps authors refine their work and insights.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Zero Tolerance for Harassment:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm has a
                            zero-tolerance policy for any form of harassment,
                            including but not limited to offensive language,
                            personal attacks, or discrimination.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Creating a safe and
                            inclusive space for all community members.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Content Relevance:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Comments and discussions
                            should be relevant to the content and topics
                            presented on Softorithm. Off-topic or spammy
                            contributions will be moderated.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Maintaining a focused
                            and valuable discussion space.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Moderation Process:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Softorithm reserves the
                            right to moderate and, if necessary, remove content
                            that violates community guidelines. Repeat offenders
                            may face account restrictions.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Ensuring the community
                            remains a positive and constructive space for all
                            participants.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Reporting Violations:</strong>
                        <ul>
                          <li>
                            <strong>Policy:</strong> Users are encouraged to
                            report any violations of community guidelines
                            through designated reporting channels.
                          </li>
                          <li>
                            <strong>Rationale:</strong> Enabling the community
                            to play a role in upholding a positive environment.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="manageaccount-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col_005,
                    })}
                    type="button"
                    onClick={t_col005}
                    style={{ cursor: "pointer" }}
                  >
                    What Do You Envision for Softorithm?
                  </button>
                </h2>
                <Collapse isOpen={col_005} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <strong>Considerations for Readers:</strong>
                        <ul>
                          <li>
                            <strong>Relevant and Valuable Content:</strong>{" "}
                            Curate content that is relevant, valuable, and
                            aligns with the interests of your target audience.
                          </li>
                          <li>
                            <strong>Engaging and User-Friendly Design:</strong>{" "}
                            Prioritize a user-friendly design for the website to
                            enhance the reading experience for visitors.
                          </li>
                          <li>
                            <strong>Community Interaction:</strong> Foster a
                            sense of community by encouraging reader engagement
                            through comments, discussions, and feedback.
                          </li>
                          <li>
                            <strong>Diverse Content:</strong> Offer a diverse
                            range of content to cater to different interests
                            within your target audience.
                          </li>
                          <li>
                            <strong>Quality Assurance:</strong> Maintain high
                            editorial standards to ensure that the content
                            published on the platform is of high quality.
                          </li>
                          <li>
                            <strong>Reader-Focused Features:</strong> Introduce
                            features that enhance the reader experience, such as
                            easy navigation and related content suggestions.
                          </li>
                          <li>
                            <strong>Clear Communication:</strong> Communicate
                            openly with readers about updates, changes, or
                            events related to the platform.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Considerations for Writers:</strong>
                        <ul>
                          <li>
                            <strong>Clear Submission Guidelines:</strong>{" "}
                            Clearly communicate guidelines for guest post
                            submissions, including content requirements and
                            formatting preferences.
                          </li>
                          <li>
                            <strong>Support and Assistance:</strong> Provide
                            assistance to writers during the submission process
                            and address technical issues promptly.
                          </li>
                          <li>
                            <strong>Transparent Policies:</strong> Clearly
                            outline rights and ownership policies, editorial
                            guidelines, and expectations regarding promotion and
                            backlinks.
                          </li>
                          <li>
                            <strong>Recognition and Attribution:</strong> Ensure
                            writers receive proper recognition for their
                            contributions by displaying author bylines and bios.
                          </li>
                          <li>
                            <strong>Feedback and Improvement:</strong> Encourage
                            constructive feedback on submitted content to foster
                            a collaborative environment for writers to enhance
                            their skills.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GuestPostFaqs;
