import { useState } from "react";

import sidebarContext from "./sidebarContext";



const SidebarState = (props) => {

    const [sidebarstate,setsidebarState] = useState(false);

  return (
    <sidebarContext.Provider value={{sidebarstate,setsidebarState}}>
      {props.children}
    </sidebarContext.Provider>
  );
}

export default SidebarState;
