import React from "react";
import { Link } from "react-router-dom";
import {
  ConverterList,
  TextEditorList,
  GeneratorsList,
  ImageEditorList,
} from "../index";
import CalculatorsList from "./CalculatorsList";
const SidebarToolPages = ({
  handleClick,
  closeSidebar,
  converterActive,
  textEditorActive,
  generatorsActive,
  calculatorActive,
  imageEditorActive,
}) => {
  return (
    <>
      <ul className="side_ul mb-2">
        <li className={`${converterActive ? "active" : ""}`}>
          <Link
            to=""
            className={`${converterActive ? "active" : ""}`}
            onClick={handleClick.converterClick}
          >
            <span>
              <i className="ri-exchange-2-line"></i>
            </span>

            <span className="cat_title">Converter</span>
          </Link>

          <ul className="side_ul_in" onClick={closeSidebar}>
            <ConverterList />
          </ul>
        </li>

        <li className={`${textEditorActive ? "active" : ""}`}>
          <Link
            to=""
            className={`${textEditorActive ? "active" : ""}`}
            onClick={handleClick.editorClick}
          >
            <span>
              <i className="ri-file-edit-line"></i>
            </span>

            <span className="cat_title">Text Editer</span>
          </Link>

          <ul className="side_ul_in" onClick={closeSidebar}>
            <TextEditorList />
          </ul>
        </li>

        <li className={`${generatorsActive ? "active" : ""}`}>
          <Link
            to=""
            className={`${generatorsActive ? "active" : ""}`}
            onClick={handleClick.generatorClick}
          >
            <span>
              <i className="ri-ai-generate"></i>
            </span>

            <span className="cat_title">Generators</span>
          </Link>

          <ul className="side_ul_in" onClick={closeSidebar}>
            <GeneratorsList />
          </ul>
        </li>

        <li className={`${calculatorActive ? "active" : ""}`}>
          <Link
            to=""
            className={`${calculatorActive ? "active" : ""}`}
            onClick={handleClick.calculatorClick}
          >
            <span>
              <i className="ri-calculator-line"></i>
            </span>

            <span className="cat_title">Calculators</span>
          </Link>

          <ul className="side_ul_in" onClick={closeSidebar}>
            <CalculatorsList />
          </ul>
        </li>

        {/* <li className={`${downloaderActive ? " active" : ""}`}>
            <Link
              to=""
              className={`${downloaderActive ? "active" : ""}`}
              onClick={handleClick.downloaderClick}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-file-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z" />
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                </svg>
              </span>

              <span className="cat_title">Downloader</span>
            </Link>

            <ul className="side_ul_in" onClick={closeSidebar}>
              <DownloaderList />
            </ul>
          </li> */}

        <li className={`${imageEditorActive ? "active" : ""}`}>
          <Link
            to=""
            className={`${imageEditorActive ? "active" : ""}`}
            onClick={handleClick.imageEditorClick}
          >
            <span>
              <i className="ri-image-edit-line"></i>
            </span>

            <span className="cat_title">Image Editor</span>
          </Link>

          <ul className="side_ul_in" onClick={closeSidebar}>
            <ImageEditorList />
          </ul>
        </li>
      </ul>
    </>
  );
};

export default SidebarToolPages;
