import React, { useState, useEffect } from "react";

const Detailtext = ({
  workingText,
  workingPoints,
  benefitText,
  benefitPoints,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const index = workingPoints.indexOf(":");
  // const firstPart = workingPoints.slice(0, index + 1);
  // const secondPart = workingPoints.slice(index + 1);

  return (
    <>
      {/* {!isMobile && ( */}
      <div className="text-align-left reasonable_mrgn">
        <p>
          <strong>{workingText} </strong>
        </p>
        <ul className="workingPoints_list">
          {workingPoints?.map((sentence, index) => (
            <li className="mt-2" key={index}>
              <i className='ri-check-double-line list_ck_icn'></i> <p className="mb-0 listCont"><strong>{sentence.split(":")[0]}:</strong>
                {sentence.split(":").slice(1).join(":")}</p>
            </li>
          ))}
        </ul>

        <p className="mt-4">
          <strong>{benefitText} </strong>
        </p>
        <ul className="workingPoints_list">
          {benefitPoints?.map((sentence, index) => (
            <li className="mt-2" key={index}>
              <i className='ri-check-double-line list_ck_icn'></i> <p className="mb-0 listCont"><strong>{sentence.split(":")[0]}:</strong>
                {sentence.split(":").slice(1).join(":")}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* )} */}
    </>
  );
};

export default Detailtext;
