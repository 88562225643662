// ImageTwo.js
import React, { useState } from "react";
import { Label, Input } from "reactstrap";

const ImageTwo = ({ validation }) => {
  const [imageTwo, setImageTwo] = useState("");

  const handleImageTwoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 100 * 1024) {
      const imageURL = URL.createObjectURL(file);
      setImageTwo(imageURL);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageString = event.target.result;
        validation.setFieldValue("imgTwo", base64ImageString);
        validation.setFieldError("imgTwo", ""); // Clear any previous error
    
      };
      reader.readAsDataURL(file);
    }
    else {
      validation.setFieldError(
        "imgTwo",
        "File size should be less than or equal to 70 KB"
      );
    }
  };

  return (
    <div className="text-left">
      <div className="position-relative d-inline-block">
        <div className="position-absolute bottom-0 end-0">
          <Label htmlFor="customer-image-input-two" className="mb-0">
            <div className="avatar-xs cursor-pointer">
              <div
                className="avatar-title bg-light border rounded-circle text-muted"
                style={{ cursor: "pointer" }}
              >
                <i className="ri-image-fill p-1"></i>
              </div>
            </div>
          </Label>
          <Input
            name="imgTwo"
            className="form-control d-none"
            id="customer-image-input-two"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleImageTwoChange}
            onBlur={validation.handleBlur.imgTwo}
            invalid={validation.touched.imgTwo && validation.errors.imgTwo}
          />
        </div>
        <div className="avatar-lg p-1">
          <div
            className="avatar-title bg-light"
            style={{ width: "130px", height: "120px", borderRadius: "5px" }}
          >
            <img
              src={validation.values.imgTwo || imageTwo}
              alt=""
              id="customer-img-two"
              className="avatar-lg object-cover"
              style={{
               
                height: "100%",
                borderRadius: "5px",
              }}
            />
          </div>
        </div>
      </div>
      {validation.errors.imgTwo &&  (
        <div className="text-danger">{validation.errors.imgTwo}</div>
      )}
    </div>
  );
};

export default ImageTwo;
