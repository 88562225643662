import {
  registerSuccess,
    apiError,
    reset_register_flag,
    registerRequest,
  } from "./reducer";
  
  
  import { toast } from "react-toastify";
  import { register } from "./service";
  
  export const RegisterUser = (values,navigate) => async (dispatch) => {
    try {
      dispatch(registerRequest());
  
      const response = await register(values);
  
      if (response.status) {
       // console.log(response);
        // Save user data to session storage
        sessionStorage?.setItem("authUser", JSON?.stringify(response));
  
        dispatch(registerSuccess(response));
  
        navigate("/");
      } else {
        dispatch(apiError(response.message)); // Dispatch an error action
        toast.error(response.message, { theme: "colored" });
      }
    } catch (error) {
      // Handle network errors or unexpected errors
  
      dispatch(apiError(error)); // Dispatch an error action
      toast.error(error, { theme: "colored" });
    }
  };
  
  export const resetRegisterFlag = () => (dispatch) => {
    // Reset the login flag without asynchronous operations
    dispatch(reset_register_flag());
  };