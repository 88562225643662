import React, { useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { getRecentApprovedPosts } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";

const RecentPost = () => {
  const dispatch = useDispatch();

  const { recentApprovedPost, isRecentApprovedPostSuccess } = useSelector(
    (state) => ({
      recentApprovedPost: state.Posts.recentApprovedPost,
      isRecentApprovedPostSuccess: state.Posts.isRecentApprovedPostSuccess,
    })
  );

  useEffect(() => {
    if (recentApprovedPost && !recentApprovedPost.length) {
      dispatch(getRecentApprovedPosts());
    }
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Recent Posts</h5>
        </CardHeader>
        <CardBody>
          {isRecentApprovedPostSuccess ? (
            <>
              {recentApprovedPost &&
                recentApprovedPost.map((item, key) => (
                  <Link
                    to={`/blog-detail/${item.id}`}
                    key={key}
                    className="text-decoration-none"
                  >
                    <div className="d-flex mb-3">
                      <div className="flex-shrink-0">
                        <img
                          src={item.imgOne}
                          alt=""
                          className="avatar-xxs"
                          height={"38px"}
                          width={"65px"}
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="mb-1">{item.title}</h6>
                        <p className="text-muted mb-0">
                          {item?.createdDate &&
                            new Date(item?.createdDate)
                              .toISOString()
                              .split("T")[0]}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}

              <div>
                <Link to="/blogs" className="btn btn-success w-100">
                  View All Posts{" "}
                  <i className="ri-arrow-right-line align-bottom"></i>
                </Link>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentPost;
