import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { designPricing } from "./DummyData";
import "../../sass/design.scss";
import Detailtext from "../../../components/Detailtext";
import TextSlicer from "../../../components/TextSlicer";

const description1 = `In essence, Softorithm's graphic design services offer a comprehensive suite of visual elements that work harmoniously to enhance and solidify the visual identity of your brand or business across a multitude of platforms and applications.`;
const workingPoints = [
  "Distinctive Business Logos: Softorithm crafts unique and memorable logos that encapsulate your brand identity, making a lasting visual impression on your audience.",
  "Eye-Catching Business Promotion Flyers: Our graphic design services produce visually appealing and informative flyers, ensuring your business promotions stand out and captivate your target market.",
  "Comprehensive Business Branding Across Various Platforms: From stationary and garments to sports branding, Softorithm ensures a consistent visual identity, reinforcing your brand across diverse mediums.",
  "Professional Brochure Branding: Softorithm designs engaging brochures that effectively communicate your brand story, values, and offerings, enhancing your professional image.",
  "Striking Business Cards: Elevate your networking efforts with carefully designed business cards that reflect your brand's personality and leave a positive and memorable impression.",
  "Immersive Branding Mockups: Softorithm creates branding mockups that provide a realistic preview of your brand elements in various contexts, helping you envision and refine your brand presentation.",
  "Engaging Social Media Posts: We design visually stunning social media posts that align with your brand aesthetics, fostering engagement and creating a cohesive online presence.",
  "Compelling YouTube Thumbnails: Softorithm ensures that your YouTube videos capture attention with thumbnails that are not only visually appealing but also consistent with your brand identity.",
  "Professional Business Stamps: Our graphic design services extend to the creation of business stamps, adding a touch of professionalism and brand uniformity to your physical documents.",
  "Eye-Catching Branding Banners: Softorithm designs banners that effectively convey your brand message, whether for physical locations or online platforms, ensuring a strong visual impact.",
  "Strategic Business Advertisement Design: Elevate your advertising campaigns with visually compelling designs that enhance the effectiveness of your messaging and increase brand recall.",
  "Attractive Product Packaging: Softorithm creates packaging designs that not only protect your products but also serve as a visual extension of your brand, making a memorable impression on consumers.",
];

const description2 = `By combining these elements, Softorithm ensures that every aspect of the UI/UX design process is geared towards enhancing the overall user experience on your digital platforms, resulting in increased user satisfaction, engagement, and the achievement of your business goals.`;

// const workingPoints2 = [
//   "In-Depth User Research: Softorithm initiates the design process by conducting comprehensive user research, gaining insights into user behaviors, preferences, and pain points to inform the overall UX strategy.",
//   "User-Centric Personas: We create detailed user personas, allowing us to tailor the UI/UX design to the specific needs and expectations of your target audience.",
//   "Optimized Information Architecture: Softorithm determines a strategic information architecture, ensuring that content is organized intuitively for users, enhancing their ability to navigate and find information seamlessly.",
//   "Intuitive User Flows and Wireframes: We develop user flows and wireframes that outline the logical progression of interactions, providing a visual guide for the overall structure and functionality of the digital platform.",
//   "Prototyping and Rigorous User Testing: Softorithm utilizes prototyping techniques to create interactive models for user testing, allowing for valuable insights and refinements based on user feedback, resulting in a more user-friendly interface.",
//   "Unified Mobile and Web Application Designs: Our UI/UX services extend seamlessly across both mobile and web applications, ensuring a consistent and cohesive user experience across different platforms.",
//   "Strategic Landing Page Designs: Softorithm crafts landing page designs that are not only visually appealing but also strategically structured to capture user attention and guide them towards desired actions.",
// ];

const Design = () => {
  return (
    <>
      <div className="design">
        <div className="page_body">
          <div className="container mt-5">
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="text-center mb-4 pb-2 about_rgt_cont ">
                  <h4 className="fw-semibold fs-22">
                    Choose the Design Service that's right for you
                  </h4>
                  <TextSlicer
                    text={`Elevate your brand with Softorithm's exceptional Graphic and UI/UX Design services. Our adept team seamlessly merges creativity and functionality, delivering visually stunning designs. From captivating logos to tailored marketing collateral, we ensure each graphic aligns with your brand identity. In UI/UX, we prioritize user experience, creating intuitive interfaces that boost engagement. Trust Softorithm to transform your ideas into visually compelling designs, exceeding your business objectives.
                `}
                  />
                  /
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xl={9}>
                <Row className="justify-content-center">
                  {(designPricing || []).map((price2, key) => (
                    <Col md={6} lg={4} xl={4} key={key}>
                      <Card className="pricing-box ribbon-box right">
                        <CardBody className="p-4 m-2">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="mb-1 fw-semibold">
                                {price2.type}
                              </h5>
                              <p className="text-muted mb-0">
                                {price2.purpose}
                              </p>
                            </div>
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light rounded-circle text-primary">
                                <i className={"fs-20 " + price2.icon}></i>
                              </div>
                            </div>
                          </div>

                          <hr className="my-4 text-muted" />
                          <div>
                            <ul className="list-unstyled text-muted vstack gap-3">
                              {(price2.points || []).map((point, key) => (
                                <li key={key}>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">{point}</div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div className="mt-3 pt-2">
                            <Link
                              to="/contact-us"
                              className={`btn btn-success w-100`}
                            >
                              Get Quote
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <div>
              <Detailtext
                workingText={
                  "How can our graphic design services elevate the visual identity of your brand or business?"
                }
                workingPoints={workingPoints}
              />
              <p className="reasonable_mrgn">{description1}</p>

              <Detailtext
                workingText={
                  "How can Softorithm's UI/UX services enhance the overall user experience on your digital platforms?"
                }
                workingPoints={workingPoints}
              />
              <p className="reasonable_mrgn">{description2}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Design;
