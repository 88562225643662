// ImageThree.js
import React, { useState } from "react";
import { Label, Input } from "reactstrap";

const ImageThree = ({ validation }) => {
  const [imageThree, setImageThree] = useState("");

  const handleImageThreeChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 100 * 1024) {
      const imageURL = URL.createObjectURL(file);
      setImageThree(imageURL);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageString = event.target.result;
        validation.setFieldValue("imgThree", base64ImageString);
        validation.setFieldError("imgThree", ""); // Clear any previous error
      };
      reader.readAsDataURL(file);
    } else {
      validation.setFieldError(
        "imgThree",
        "File size should be less than or equal to 70 KB"
      );
    }
  };

  return (
    <div className="text-left">
      <div className="position-relative d-inline-block">
        <div className="position-absolute bottom-0 end-0">
          <Label htmlFor="customer-image-input-three" className="mb-0">
            <div className="avatar-xs cursor-pointer">
              <div
                className="avatar-title bg-light border rounded-circle text-muted"
                style={{ cursor: "pointer" }}
              >
                <i className="ri-image-fill p-1"></i>
              </div>
            </div>
          </Label>
          <Input
            name="imgThree"
            className="form-control d-none"
            id="customer-image-input-three"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleImageThreeChange}
            onBlur={validation.handleBlur.imgThree}
            invalid={validation.touched.imgThree && validation.errors.imgThree}
          />
        </div>
        <div className="avatar-lg p-1">
          <div
            className="avatar-title bg-light"
            style={{ width: "130px", height: "120px", borderRadius: "5px" }}
          >
            <img
              src={validation.values.imgThree || imageThree}
              alt=""
              id="customer-img-three"
              className="avatar-lg object-cover"
              style={{
                height: "100%",
                borderRadius: "5px",
              }}
            />
          </div>
        </div>
      </div>
      {validation.errors.imgThree && (
        <div className="text-danger">{validation.errors.imgThree}</div>
      )}
    </div>
  );
};

export default ImageThree;
