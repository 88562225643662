import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const ServicePages = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <React.Fragment>
      <ButtonGroup>
        <UncontrolledDropdown
          // onMouseOver={() => setDropdownOpen(true)}
          // onMouseLeave={() => setDropdownOpen(false)}
          isOpen={dropdownOpen}
          toggle={toggle}
          className=" header-item topbar-user "
        >
          <DropdownToggle tag="span" className="text">
            <Link>
              Services <i className="ri-arrow-down-s-fill "></i>
            </Link>
          </DropdownToggle>
          <DropdownMenu className="border-0">
            <DropdownItem
              to="/services/web-design"
              tag={Link}
              style={{ color: "#212529" }}
            >
             Web Design
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem
              tag={Link}
              to="/services/frontend-development"
              style={{ color: "#212529" }}
            >
              Frontend Development
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem
              tag={Link}
              to="/services/digital-marketing"
              style={{ color: "#212529" }}
              className="dropdown-link"
            >
              Digital Marketing
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem
              tag={Link}
              to="/services/web-development"
              style={{ color: "#212529" }}
              className="dropdown-link"
            >
              Web Development
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              tag={Link}
              to="/services/2d-animations"
              style={{ color: "#212529" }}
              className="dropdown-link"
            >
              2D Animations
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default ServicePages;
