import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  errorMsg: "", // for error message
  isLoading: false,
  error: false, // for error
};

const registerSlice = createSlice({
  name: "register",
  initialState: initialState,
  reducers: {
    registerRequest: (state) => {
      state.isLoading = true;
      state.error = false;
    },

    apiError: (state, action) => {
      state.error = true;
      state.isLoading = false;
      state.errorMsg = action.payload;
    },

    registerSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = false;
    },

    registerUserSuccess: (state, action) => {
      state.isUserLogout = true;
      state.user = {};
    },

    reset_register_flag: (state, action) => {
      state.error = false;
      state.isLoading = false;
      state.errorMsg = "";
    },
  },
});

export const {
  apiError,
  registerSuccess,
  registerUserSuccess,
  reset_register_flag,
  registerRequest,
} = registerSlice.actions;

export default registerSlice.reducer;
