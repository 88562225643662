import React from "react";
import { NavLink } from "react-router-dom";
const TextEditorList = () => {
  return (
    <>
      <li>
        <NavLink to="/tools/compare-text">Compare Text</NavLink>
      </li>

      <li>
        <NavLink to="/tools/remove-extra-space">Remove Extraspace</NavLink>
      </li>

      <li>
        <NavLink to="/tools/remove-line-break">Remove Line Break</NavLink>
      </li>

      <li>
        <NavLink to="/tools/add-line-break">Add Line Break</NavLink>
      </li>

      <li>
        <NavLink to="/tools/remove-duplicate-lines">Remove Duplicate Lines</NavLink>
      </li>

      <li>
        <NavLink to="/tools/character-replace">Replace Character</NavLink>
      </li>

      <li>
        <NavLink to="/tools/remove-character">Remove Character</NavLink>
      </li>

      <li>
        <NavLink to="/tools/text-counter">Text Counter</NavLink>
      </li>
      <li>
        <NavLink to="/tools/email-extractor">Email Extractor</NavLink>
      </li>

      <li>
        <NavLink to="/tools/number-extractor">Number Extractor</NavLink>
      </li>

      <li>
        <NavLink to="/tools/phone-number-extractor">Phone No. Extractor</NavLink>
      </li>

      <li>
        <NavLink to="/tools/text-transform">Text transform</NavLink>
      </li>

      <li>
        <NavLink to="/tools/paragraph-to-lines">Paragraphs To Single Line</NavLink>
      </li>

      <li>
        <NavLink to="/tools/line-breaks-to-paragraph">Line Breaks to Paragraphs</NavLink>
      </li>

    </>
  );
};

export default TextEditorList;
