import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { webPricing } from "../design/DummyData";
import "../../sass/design.scss";
import Detailtext from "../../../components/Detailtext";
import TextSlicer from "../../../components/TextSlicer";

const enterpriseWebApplications = [
  "Softorithm specializes in developing enterprise-level web applications, addressing critical business functions such as project and task management, ERP (Enterprise Resource Planning), PLM (Product Lifecycle Management), and PIM (Product Information Management) software.",
  "Their expertise extends to collaboration software and intranets, facilitating efficient communication and workflow within organizations.",
  "Softorithm is adept at crafting CRM (Customer Relationship Management) systems tailored to the unique needs of businesses, enhancing customer interactions and relationship management.",
  "Financial management systems developed by Softorithm cater to the complex financial needs of businesses, ensuring accuracy and efficiency in financial processes.",
  "Document management systems, learning management systems, and knowledge management systems are among the customized solutions offered to optimize information organization and accessibility.",
  "Softorithm excels in creating custom enterprise web applications, ensuring that businesses receive tailored solutions that align with their specific requirements.",
];

const webPortals = [
  "Softorithm provides expertise in developing various types of web portals, including self-service portals, customer portals, vendor portals, partner portals, patient portals, employee portals, eLearning portals, community portals, insurance portals, and investor portals.",
  "The development of these portals is tailored to enhance user experiences, streamline processes, and foster collaboration between businesses and their stakeholders.",
];
const healthcareServices = [
  "Softorithm stands out in the healthcare industry by offering web application development services for Electronic Health Records (EHR/EMR), healthcare CRM, patient portals, and telemedicine apps.",
  "Their solutions are designed to improve healthcare service delivery, patient engagement, and overall efficiency in healthcare operations.",
];

const softorithmFeatures = [
  "Customization: Softorithm understands the unique needs of businesses and provides customized solutions that align with their goals and objectives.",
  "Expertise: With a team of skilled professionals, Softorithm has the expertise to handle complex web development projects, ensuring high-quality and effective solutions.",
  "Industry-Specific Solutions: Softorithm's capabilities extend across various industries, allowing them to develop industry-specific solutions, including healthcare applications and enterprise-level systems.",
  "Collaborative Approach: Softorithm collaborates closely with clients throughout the development process, ensuring that the end product meets their expectations and requirements.",
  "Scalability: The solutions provided by Softorithm are scalable, accommodating the growth and evolving needs of businesses over time.",
];

const WebDevelopment = () => {
  return (
    <div className="design">
      <div className="page_body">
        <div className="container mt-5">
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center mb-4 pb-2 about_rgt_cont">
                <h4 className="fw-semibold fs-22">
                  Choose the Web Developmenr Service that's right for you
                </h4>
                <TextSlicer
                  text={`Unlock efficiency and innovation with our comprehensive web development services. From powerful ERP and HRM solutions to seamless PLM and PIM integrations, we specialize in crafting dynamic web portals tailored to your unique needs. Elevate healthcare standards with our EHR/EMR systems. Experience the pinnacle of digital transformation with our expertly curated suite of services, delivering excellence in every facet of web development. Your success is our priority; let's build a future together.
               `}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl={9}>
              <Row className="justify-content-center">
                {(webPricing || []).map((price2, key) => (
                  <Col md={6} lg={4} xl={4} key={key}>
                    <Card className="pricing-box ribbon-box right">
                      <CardBody className="p-4 m-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-1 fw-semibold">{price2.type}</h5>
                            <p className="text-muted mb-0">{price2.purpose}</p>
                          </div>
                          <div className="avatar-sm">
                            <div className="avatar-title bg-light rounded-circle text-primary">
                              <i className={"fs-20 " + price2.icon}></i>
                            </div>
                          </div>
                        </div>

                        <hr className="my-4 text-muted" />
                        <div>
                          <ul className="list-unstyled text-muted vstack gap-3">
                            {(price2.points || []).map((point, key) => (
                              <li key={key}>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 text-success me-1">
                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1">{point}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-3 pt-2">
                          <Link
                            to="/contact-us"
                            className={`btn btn-success w-100`}
                          >
                            Get Quote
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <div>
            <div>
              <Detailtext
                workingText={
                  "Why should you get our website development services?"
                }
              />
              <p className="reasonable_mrgn">
                Companies, whether small, large, or multinational, contact
                Softorithm for website development services based on their
                expertise in creating customized solutions for a variety of
                business needs. The specific points that make Softorithm an
                attractive choice for website development services include:
              </p>

              <Detailtext
                workingText={
                  "Navigating Efficiency: The Power of Enterprise Web Applications:"
                }
                workingPoints={enterpriseWebApplications}
              />
              <Detailtext
                workingText={
                  "Gateway to Connectivity: Exploring Powerful Web Portals:"
                }
                workingPoints={webPortals}
              />

              <Detailtext
                workingText={
                  "Wellness in Web Code: Navigating Healthcare Through Apps:"
                }
                workingPoints={healthcareServices}
              />

              <Detailtext
                workingText={
                  "The Unmatched Benefits of Softorithm's Web Development:"
                }
                workingPoints={softorithmFeatures}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;
