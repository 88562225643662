import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const OtherPages = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <React.Fragment>
      <ButtonGroup>
        <UncontrolledDropdown
          // onMouseOver={() => setDropdownOpen(true)}
          // onMouseLeave={() => setDropdownOpen(false)}
          isOpen={dropdownOpen}
          toggle={toggle}
          className=" header-item topbar-user "
        >
          <DropdownToggle tag="span" className="text">
            <Link>
              Others <i className="ri-arrow-down-s-fill"></i>
            </Link>
          </DropdownToggle>
          <DropdownMenu className="border-0">
            <DropdownItem
              tag={Link}
              to="/contact-us"
              style={{ color: "#212529" }}
            >
              Contact
            </DropdownItem>
            {/* <DropdownItem divider /> */}
            {/* <DropdownItem tag={Link} to="/about" style={{ color: "#212529" }}>
              About
            </DropdownItem> */}
            <DropdownItem divider />
            <DropdownItem tag={Link} to="/blogs" style={{ color: "#212529" }}>
              Blog
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              tag={Link}
              to="/customers"
              style={{ color: "#212529" }}
            >
              Customers
            </DropdownItem>
            <DropdownItem divider />
            {/* <DropdownItem tag={Link} to="/team" style={{ color: "#212529" }}>
              Team
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default OtherPages;
