import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

export const getAllPosts = createAsyncThunk("post/getAllPosts", async () => {
  try {
    return await services.getPosts();
  } catch (error) {}
});

export const createNewPost = createAsyncThunk(
  "post/createNewPost",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createPost(obj);
    } catch (error) {}
  }
);

export const deletePost = createAsyncThunk("post/deletePost", async (obj) => {
  try {
    //console.log(obj)
    return await services.onDeletePost(obj);
  } catch (error) {}
});

export const onUpdatePost = createAsyncThunk(
  "post/onUpdatepost",
  async (obj) => {
    try {
      return await services.updatePost(obj);
    } catch (error) {}
  }
);


export const PostById = createAsyncThunk("post/PostById", async (obj,navigate) => {
  try {
    return await services.getPostById(obj,navigate);
  } catch (error) {}
});


export const getRecentApprovedPosts = createAsyncThunk("post/getRecentApprovedPosts", async () => {
  try {
    return await services.getRecentApprovedPosts();
  } catch (error) {}
});

export const UserPosts = createAsyncThunk("post/userPosts", async (obj) => {
  try {
    return await services.getUserPostsById(obj);
  } catch (error) {}
});


export const getAllCategories = createAsyncThunk("post/getAllCategories", async () => {
  try {
    return await services.getCategories();
  } catch (error) {}
});