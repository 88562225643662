import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { frontendPricing } from "../design/DummyData";
import "../../sass/design.scss";
import Detailtext from "../../../components/Detailtext";
import TextSlicer from "../../../components/TextSlicer";

const description1 = `These features collectively contribute to the overall reliability, performance, and success of HTML and WordPress frontends developed by Softorithm, making them a suitable choice for companies of varying sizes.`;
const frontendDevelopmentServices = [
  "Expertise and Experience: Softorithm brings a wealth of expertise and experience in HTML and WordPress frontend development. Their team of skilled professionals is well-versed in industry best practices, ensuring the delivery of high-quality and effective solutions.",
  "Customization for Brand Consistency: Softorithm provides customization options for HTML and WordPress frontends, allowing companies to maintain brand consistency. Tailored designs ensure that the website aligns with the company's branding and meets specific visual and functional requirements.",
  "SEO-Friendly WordPress Development: Softorithm understands the importance of search engine optimization (SEO). Their WordPress frontend services focus on creating SEO-friendly websites, helping companies improve online visibility and search engine rankings.",
  "Responsive and Cross-Browser Compatibility: Softorithm ensures that the HTML and WordPress frontends they develop are responsive and compatible across various devices and web browsers. This responsiveness is crucial for delivering a consistent and user-friendly experience to a diverse audience.",
  "Security Measures: Security is a top priority for Softorithm. Their HTML and WordPress frontend services incorporate best practices for security, safeguarding websites and user data against potential threats and vulnerabilities.",
  "Ongoing Maintenance and Support: Softorithm offers ongoing maintenance and support services for the frontends they develop. This commitment ensures that the websites remain up-to-date, secure, and perform optimally over time, reducing the risk of issues and downtime.",
];
const description2 = `These features collectively position Softorithm as a suitable choice for multinational companies seeking React.js frontend development services. The expertise, scalability, cross-platform compatibility, optimized user experience, integration capabilities, and agile development approach contribute to the effectiveness of React.js solutions provided by Softorithm.`;

const Frontend = () => {
  return (
    <div className="design">
      <div className="page_body">
        <div className="container mt-5">
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center mb-4 pb-2 about_rgt_cont">
                <h4 className="fw-semibold fs-22">
                  Choose the Frontend Service that's right for you
                </h4>
                <TextSlicer
                  text={`Unlock the potential of your online presence with our
                  unparalleled frontend services. From the timeless versatility
                  of HTML, empowering businesses with customizable and scalable
                  solutions, to the dynamic and user-friendly websites powered
                  by WordPress, and cutting-edge, interactive experiences
                  crafted with React JS. Our expertise spans across these key
                  technologies, ensuring your digital footprint is not just
                  impactful but also future-proof. Elevate your online journey
                  with our best-in-class HTML, WordPress, and React JS services
                  tailored to meet your unique business needs.`}
                />
                <p className="text-muted mb-4 fs-15"></p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl={9}>
              <Row className="justify-content-center">
                {(frontendPricing || []).map((price2, key) => (
                  <Col md={6} lg={4} xl={4} key={key}>
                    <Card className="pricing-box ribbon-box right">
                      <CardBody className="p-4 m-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-1 fw-semibold">{price2.type}</h5>
                            <p className="text-muted mb-0">{price2.purpose}</p>
                          </div>
                          <div className="avatar-sm">
                            <div className="avatar-title bg-light rounded-circle text-primary">
                              <i className={"fs-20 " + price2.icon}></i>
                            </div>
                          </div>
                        </div>

                        <hr className="my-4 text-muted" />
                        <div>
                          <ul className="list-unstyled text-muted vstack gap-3">
                            {(price2.points || []).map((point, key) => (
                              <li key={key}>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 text-success me-1">
                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1">{point}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-3 pt-2">
                          <Link
                            to="/contact-us"
                            className={`btn btn-success w-100`}
                          >
                            Get Quote
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <div>
            <Detailtext
              workingText={
                "How experienced is Softorithm in providing HTML and WordPress services?"
              }
            />
            <strong className="d-table reasonable_mrgn">
              Certainly! Here are six essential features that make Softorithm's
              HTML and WordPress frontend services beneficial for small to large
              companies:
            </strong>
            <Detailtext workingPoints={frontendDevelopmentServices} />
            <p className="reasonable_mrgn">{description1}</p>

            <Detailtext
              workingText={
                "In what ways does our React JS frontend service facilitate the development of fast and efficient single-page applications (SPAs), ensuring a seamless and uninterrupted user journey?"
              }
            />
            <strong className="d-table reasonable_mrgn">
              Certainly! Here are six essential features that make Softorithm's
              React.js frontend development services advantageous for
              multinational companies:
            </strong>
            <Detailtext workingPoints={frontendDevelopmentServices} />
            <p className="reasonable_mrgn">{description2}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frontend;
