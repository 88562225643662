import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  memo,
  useCallback,
} from "react";
import { Fade } from "react-bootstrap";
import { Link } from "react-router-dom";
import { cross_icn, Softorithm_White_Logo } from "../../images";
import sidebarContext from "../../context/sidebar/sidebarContext";
import AppSideBar from "../sidebar/AppSideBar";
import SmallScreen from "./smallscreen/SmallScreen";
import LargeScreen from "./largescreen/LargeScreen";

const AppHeader = memo(() => {
  const sidebarRref = useRef(null);

  const [searchtoggle, setsearchtoggle] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [text, setText] = useState("ToolMenu");
  const [hamburger, setHamburger] = useState(false);
  const [toolState, setToolState] = useState(false);

  // to use sidebar using context
  const sidebarcontext = useContext(sidebarContext);
  /// de strusture
  const { setsidebarState } = sidebarcontext;

  const handleClickOutside = useCallback(
    (event) => {
      if (sidebarRref.current && !sidebarRref.current.contains(event.target)) {
        setSideMenu(false);
        setsidebarState(false);
        setHamburger(false);
      }
    },
    [sidebarRref, setSideMenu, setsidebarState]
  );

  const openSideNav = useCallback(
    (value) => {
      if (value === "ToolMenu") {
        setHamburger(false);
        setToolState(!toolState);
        setSideMenu(!toolState);
        setsidebarState(!toolState);
        setText(value);
      } else if (value === "pageMenu") {
        setToolState(false);
        setHamburger(!hamburger);
        setSideMenu(!hamburger);
        setsidebarState(!hamburger);
        setText(value);
      }
    },
    [
      hamburger,
      setHamburger,
      toolState,
      setToolState,
      setSideMenu,
      setsidebarState,
    ]
  );

  const closeSidebar = () => {
    setSideMenu(false);
    setsidebarState(false);
    setHamburger(false);
    setToolState(false);

  };

  const handleNavbarClick = (e) => {
    if (
      !e.target.classList.contains("ri-tools-line") &&
      !e.target.classList.contains("toggle_bar") &&
      !e.target.classList.contains("hamburger_icn")
    ) {
      closeSidebar();
    }
  };

  const itemClick = useCallback(
    (closeBar) => {
      if (sideMenu) {
        setSideMenu(closeBar);
        setsidebarState(closeBar);
        setHamburger(false);
        setToolState(false)
      }
    },
    [setsidebarState, sideMenu]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="AppHeader_Sidebar" ref={sidebarRref}>
        <Fade
          className={searchtoggle ? "fadeIn " : "fadeOut "}
          in={searchtoggle}
          unmountOnExit={!searchtoggle}
        >
          <div
            className={`search_bx_overlay`}
            id="search_bx_overlay"
            style={{
              display: searchtoggle ? "block" : "",
              zIndex: searchtoggle ? "11" : "",
            }}
          >
            <span
              className="overlay_dismiss"
              onClick={() => {
                setsearchtoggle(false);
              }}
            >
              <img src={cross_icn} alt="Cross Icon"></img>
            </span>

            <div className="tp_search ">
              <input className="form-control" placeholder="Search"></input>

              <span className="search_icn">
                <i className="ri-search-line"></i>
              </span>
            </div>
          </div>
        </Fade>

        <div className="app_top_nav" onClick={handleNavbarClick}>
          <div className="app_logo">
            <Link to="/">
              <img src={Softorithm_White_Logo} alt="Logo"></img>
            </Link>
          </div>

          <div className="app_other_links">
            <div className="tp_search">
              <input className="form-control" placeholder="Search"></input>

              <span className="search_icn">
                <i className="ri-search-line"></i>
              </span>
            </div>

            <LargeScreen />
          </div>
          {/* Small Screen Size */}
          <SmallScreen
            setsearchtoggle={setsearchtoggle}
            setHamburger={setHamburger}
            hamburger={hamburger}
            setToolState={setToolState}
            toolState={toolState}
            openSideNav={openSideNav}
          />
        </div>

        <AppSideBar itemClick={itemClick} text={text} />
      </div>
    </>
  );
});

export default AppHeader;
