import React from "react";
import TextSlicer from "../../components/TextSlicer";
import { Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import {
  cumstomerImg1,
  cumstomerImg2,
  cumstomerImg3,
  cumstomerImg4,
  cumstomerImg5,
  cumstomerImg6,
  cumstomerImg7,
  cumstomerImg8,
  cumstomerImg9,
  cumstomerImg10,
  cumstomerImg11,
  cumstomerImg12,
  cumstomerImg13,
  cumstomerImg14,
  cumstomerImg15,
  cumstomerImg16,
  cumstomerImg17,
  cumstomerImg18,
  cumstomerImg19,
  cumstomerImg20,
  cumstomerImg21,
  cumstomerImg22,
  cumstomerImg23,
  cumstomerImg24,
  cumstomerImg25,
  cumstomerImg26,
  cumstomerImg27,
  cumstomerImg28,
  cumstomerImg29,
  cumstomerImg30,
  cumstomerImg31,
  cumstomerImg32,
  cumstomerImg33,
  cumstomerImg34,
  cumstomerImg35,
  cumstomerImg36,
  cumstomerImg37,
  cumstomerImg38,
  cumstomerImg39,
  cumstomerImg40,
  cumstomerImg41,
  cumstomerImg42,
  cumstomerImg43,
  cumstomerImg44,
  cumstomerImg45,
  cumstomerImg46,
  cumstomerImg47,
  cumstomerImg48,
  cumstomerImg49,
} from "../../images";
import MetaData from "../../components/MetaData";
const description = `At Softorithm, we pride ourselves on providing exceptional web development, graphic design, UI/UX design, and digital marketing services through our team of passionate professionals. We take great satisfaction in offering innovative solutions to satisfy the particular requirements of our esteemed clients. Together, we can improve user experiences, boost your online visibility, and help you succeed in the digital sphere.`;

const images = [
  cumstomerImg1,
  cumstomerImg2,
  cumstomerImg3,
  cumstomerImg4,
  cumstomerImg5,
  cumstomerImg6,
  cumstomerImg7,
  cumstomerImg8,
  cumstomerImg9,
  cumstomerImg10,
  cumstomerImg11,
  cumstomerImg12,
  cumstomerImg13,
  cumstomerImg14,
  cumstomerImg15,
  cumstomerImg16,
  cumstomerImg17,
  cumstomerImg18,
  cumstomerImg19,
  cumstomerImg20,
  cumstomerImg21,
  cumstomerImg22,
  cumstomerImg23,
  cumstomerImg24,
  cumstomerImg25,
  cumstomerImg26,
  cumstomerImg27,
  cumstomerImg28,
  cumstomerImg29,
  cumstomerImg30,
  cumstomerImg31,
  cumstomerImg32,
  cumstomerImg33,
  cumstomerImg34,
  cumstomerImg35,
  cumstomerImg36,
  cumstomerImg37,
  cumstomerImg38,
  cumstomerImg39,
  cumstomerImg40,
  cumstomerImg41,
  cumstomerImg42,
  cumstomerImg43,
  cumstomerImg44,
  cumstomerImg45,
  cumstomerImg46,
  cumstomerImg47,
  cumstomerImg48,
  cumstomerImg49,
];
const Customer = () => {
  return (
    <>
      <MetaData
        metaTitle={"Customer"}
        metaDescription={
          "At Softorithm, we pride ourselves on providing exceptional web development, graphic design, UI/UX design, and digital marketing services through our team of passionate professionals."
        }
      />
      <div className="page_body">
        <div className="container mt-5">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <div className="about_rgt_cont text-center">
                <h2 className="text-center mb-3">Customers</h2>
                <TextSlicer text={description} />
              </div>
            </div>
            <Row className="justify-content-center mt-3">
              {images.map((image, index) => (
                <Col key={index} md={4} sm={6}>
                  <Card>
                    <CardBody className="p-0 text-center">
                      <div className="customer_img_bx rounded">
                        <img src={image} alt="" className="img-fluid" />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
