import React, { useState, useEffect } from "react";

const TextSlicer = ({ text }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showFullText, setShowFullText] = useState(false);
  const maxLength = 130;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const slicedText = showFullText ? text : text.slice(0, maxLength) + "...";

  return (
    <>
      {isMobile ? (
        <p>
          {text.length > maxLength ? (
            <>
              {slicedText}
              <span
                style={{ cursor: "pointer" }}
                className="link-success"
                onClick={toggleText}
              >
                {showFullText ? (
                  <i
                    className="ri-arrow-up-circle-line ri-lg"
                    title="Show less"
                  ></i>
                ) : (
                  <i
                    className="ri-arrow-down-circle-line ri-lg"
                    title="Show more"
                  ></i>
                )}
              </span>
            </>
          ) : (
            text
          )}
        </p>
      ) : (
        <p>{text}</p>
      )}
    </>
  );
};

export default TextSlicer;
