import React from "react";

import { NavLink } from "react-router-dom";
const ConverterList = () => {

  return (
    <>
      <li>
        <NavLink to="/tools/image-compress">Image Compressor</NavLink>
      </li>
      <li>
        <NavLink to="/tools/jpg-to-png">JPG to PNG </NavLink>
      </li>
      <li>
        <NavLink to="/tools/png-to-jpg">PNG to JPG</NavLink>
      </li>
      <li>
        <NavLink to="/tools/png-to-pdf">PNG to PDF</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pdf-to-png">PDF to PNG</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pdf-to-jpg">PDF to JPG</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pdf-to-word">PDF to WORD</NavLink>
      </li>
      {/* <li>
        <Link  to="/tools/wordtopdf">WORD to PDF</Link>
      </li> */}
      <li>
        <NavLink to="/tools/mp4-to-mp3">MP4 to MP3</NavLink>
      </li>
      <li>
        <NavLink to="/tools/mp3-to-mp4">MP3 to MP4</NavLink>
      </li>
      <li>
        <NavLink to="/tools/png-to-gif">PNG to GIF</NavLink>
      </li>
      <li>
        <NavLink to="/tools/jpg-to-gif">JPG to GIF</NavLink>
      </li>

      <li>
        {/* <NavLink to="/tools/bg-remover">Background Remover</NavLink> */}
      </li>

      <li>
        <NavLink to="/tools/image-resizer">Image Resizer</NavLink>
      </li>
    </>
  );
};

export default ConverterList;
