import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OtherPages from "./OtherPages";
import ServicePages from "./ServicePages";
import ProfileDropdown from "./ProfileDropdown";
const LargeScreen = () => {
  const [user, setUser] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      // console.log(obj)
      setUser(user);
    }
  }, []);
  

  return (
    <>
      <div style={{ whiteSpace: "nowrap" }}>
        <ul className="tp_ul">
          {user && (
            <li>
              <Link to="/write-post">
                Write Post <i className="ri-edit-box-line small"></i>{" "}
              </Link>
            </li>
          )}

          <li>
            <Link to="/tools/image-compress">Tools</Link>
          </li>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            {/* <Link to="/product">Product</Link> */}
          </li>

          <li>
            {/* <Link to="/services">Services</Link> */}
            <ServicePages />
          </li>

          <li>
            <Link to="/guest-post">Guest Post</Link>
          </li>
          {/* 
                <li>
                  <Link to="/contact-us">Contact</Link>
                </li> */}

          <li>
            <OtherPages />
          </li>

          <li>
            <Link to="/register">
              <i className="ri-login-box-line register_icn"></i>
            </Link>
          </li>

          <li>
            {/* <Link to="/register">
              <svg
                id="account"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M18,8.991A9,9,0,1,0,3.186,15.843c.018.018.036.018.036.036.162.126.324.252.5.378a2.406,2.406,0,0,1,.252.2,8.985,8.985,0,0,0,10.082,0c.088-.053.16-.124.25-.179.162-.125.342-.251.5-.377.018-.018.036-.018.036-.036A9.152,9.152,0,0,0,18,8.99ZM9,16.867a7.811,7.811,0,0,1-4.518-1.439A3.67,3.67,0,0,1,4.572,15a4.691,4.691,0,0,1,.468-1.115,3.958,3.958,0,0,1,.72-.918,5.054,5.054,0,0,1,.918-.719,4.543,4.543,0,0,1,1.1-.45,4.785,4.785,0,0,1,4.518,1.151,4.506,4.506,0,0,1,.972,1.457,4.783,4.783,0,0,1,.27,1.025A7.909,7.909,0,0,1,9,16.867ZM6.246,8.542a2.95,2.95,0,0,1,0-2.3,3.07,3.07,0,0,1,1.6-1.6A2.807,2.807,0,0,1,9,4.405a2.749,2.749,0,0,1,1.152.234,3.07,3.07,0,0,1,1.6,1.6,2.806,2.806,0,0,1,.234,1.151,2.741,2.741,0,0,1-.234,1.151,3.067,3.067,0,0,1-1.6,1.6,3.055,3.055,0,0,1-2.322,0A3.2,3.2,0,0,1,6.876,9.5a2.842,2.842,0,0,1-.63-.954ZM14.6,14.511c0-.036-.018-.054-.018-.09a5.873,5.873,0,0,0-.774-1.581,5.494,5.494,0,0,0-1.224-1.277,5.858,5.858,0,0,0-1.17-.684,3.173,3.173,0,0,0,.522-.431,4.725,4.725,0,0,0,.7-.882A4.077,4.077,0,0,0,13.23,7.39a4.174,4.174,0,0,0-.324-1.654,4.274,4.274,0,0,0-.918-1.349,4.326,4.326,0,0,0-1.35-.9,4.4,4.4,0,0,0-3.312,0,4.085,4.085,0,0,0-1.35.917,4.32,4.32,0,0,0-.9,1.349,4.174,4.174,0,0,0-.324,1.654,3.914,3.914,0,0,0,.162,1.133,4.138,4.138,0,0,0,.45,1.043,4.09,4.09,0,0,0,.7.882,3.154,3.154,0,0,0,.54.431,5.76,5.76,0,0,0-1.17.7,6.236,6.236,0,0,0-1.224,1.259,5.624,5.624,0,0,0-.774,1.582.2.2,0,0,0-.018.09A7.873,7.873,0,1,1,16.884,8.991,7.83,7.83,0,0,1,14.6,14.512Z"
                  fill="#2ca876"
                ></path>
              </svg>
            </Link> */}
            {user && <ProfileDropdown /> }
            
          </li>
        </ul>
      </div>
    </>
  );
};

export default LargeScreen;
