import React from 'react'

const SmallScreen = ({setsearchtoggle,setHamburger,hamburger,setToolState,toolState,openSideNav}) => {
  return (
    <>
      <div className="small_view_head">
            <div
              className="ssearch_icn"
              onClick={() => {
                setsearchtoggle(true);
              }}
            >
              <i className="ri-search-line"></i>
            </div>
            <div
              className="ssearch_icn tool_icon"
              onClick={() => {
                setHamburger(false);
                setToolState(!toolState);
                openSideNav("ToolMenu");
              }}
            >
              <i
                className="ri-tools-line "
                style={{ fontSize: "x-large", cursor: "pointer" }}
                title="Tools"
              ></i>
            </div>

            <div
              className={`hamburger_icn ${hamburger ? "openedMenu" : ""}`}
              onClick={() => {
                openSideNav("pageMenu");
                setHamburger(!hamburger);
              }}
            >
              <span className="toggle_bar lv_dn"></span>
            </div>
          </div>
    </>
  )
}

export default SmallScreen
