import React from 'react'
import '../sass/notfound.scss'
const NotFound = () => {
  return (
    <div className="not_found  p-2 m-2  shadow rounded-2">
      <h2>404</h2>
      <p>Page not found</p>
    </div>
  );
};

export default NotFound;
