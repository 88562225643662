import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { animationPricing } from "../design/DummyData";
import "../../sass/design.scss";
import Detailtext from "../../../components/Detailtext";
import TextSlicer from "../../../components/TextSlicer";

const animationServices = [
  "Creative Expertise in 2D Animation: Softorithm boasts a team of creative professionals with expertise in 2D animation, providing visually appealing and engaging animations tailored to the needs of businesses.",
  "Customized Animation Solutions: Recognizing the unique requirements of each client, Softorithm offers customized 2D animation solutions that align with the brand identity and messaging of small, large, and multinational companies.",
  "Storytelling Through Animation: Softorithm excels in storytelling through animation, effectively conveying complex ideas or messages in a visually compelling and easy-to-understand format, enhancing communication for businesses.",
  "Brand Consistency: Softorithm ensures that 2D animations maintain brand consistency, incorporating logos, colors, and other brand elements to reinforce the identity of the business.",
  "Versatile Animation Styles: Softorithm adapts to various animation styles, from explainer videos to character animations, offering versatility to meet the diverse needs of businesses across different industries.",
];

const audioServices = [
  "Precision in Noise Reduction: Experience crystal-clear audio with our advanced noise reduction techniques. We meticulously eliminate unwanted background noise, ensuring your message is heard loud and clear.",
  "Expert Audio Enhancement: Our team of audio professionals is dedicated to enhancing the overall quality of your audio content. Softorithm ensures that every nuance of sound is refined, delivering a superior auditory experience.",
  "Say Goodbye to Audio Noise: We specialize in removing all types of audio noise, providing you with pristine and professional-sounding recordings. Your content deserves to be heard without distractions, and we make sure it stands out.",
  "Mastering Excellence: Softorithm's audio mastering services elevate your audio to its full potential. Our mastering techniques balance and optimize every element, creating a polished and broadcast-ready final product.",
  "Immersive Audio Mixing: Trust our expertise in audio mixing to blend elements seamlessly. Whether it's a podcast, music track, or promotional material, we ensure that all components harmonize for an immersive listening experience.",
  "Powerful Audio Boosting: Softorithm goes beyond just editing; we boost your audio to make it impactful and dynamic. Our audio boosting services bring out the best in your content, making it stand out in any context.",
];

const videoCreationServices = [
  "Dynamic YouTube Video Creation: Softorithm brings your ideas to life with engaging YouTube video creation. From concept to execution, we ensure your content stands out in the crowded digital landscape, maximizing viewer engagement.",
  "Seamless Camera Footage Merging: Say goodbye to disjointed clips! Softorithm specializes in merging your camera footage seamlessly. We craft a fluid narrative, enhancing the flow of your story for a polished and professional outcome.",
  "Special Effects Magic: Elevate your video clips with mesmerizing special effects. Softorithm adds a touch of magic to make your content visually striking, ensuring it leaves a lasting impression on your audience.",
  "Digital Menus for Restaurants: Softorithm transforms dining experiences with visually appealing digital menus. Our video editing services bring your menu to life, enticing customers with enticing visuals and showcasing your offerings in the best light.",
  "Business Intro Videos: Make a lasting first impression with Softorithm's business intro videos. We craft compelling visuals that tell your brand story, leaving a memorable mark on potential clients and customers.",
  "Reels/Shorts Creation: Short-form content is king, and Softorithm knows how to make it shine. Our expertise in reels and shorts creation ensures that your message is concise, impactful, and perfectly tailored for today's digital audience.",
];

const Animations = () => {
  return (
    <>
      <div className="design">
        <div className="page_body">
          <div className="container mt-5">
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="text-center mb-4 pb-2 about_rgt_cont">
                  <h4 className="fw-semibold fs-22">
                    Choose the 2D Animation Service that's right for you
                  </h4>
                  <TextSlicer
                    text={`Elevate your brand with our top-notch 2D animation services. Our expert animators bring creativity and precision to every project, ensuring a captivating visual experience. From concept to execution, we tailor animations to suit your unique needs, delivering compelling narratives and engaging visuals. Transform your ideas into dynamic, animated content that leaves a lasting impression. At Softorithm, we take pride in providing unparalleled 2D animation services that breathe life into your vision.
                 `}
                  />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xl={9}>
                <Row className="justify-content-center">
                  {(animationPricing || []).map((price2, key) => (
                    <Col md={6} lg={4} xl={4} key={key}>
                      <Card className="pricing-box ribbon-box right">
                        <CardBody className="p-4 m-2">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="mb-1 fw-semibold">
                                {price2.type}
                              </h5>
                              <p className="text-muted mb-0">
                                {price2.purpose}
                              </p>
                            </div>
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light rounded-circle text-primary">
                                <i className={"fs-20 " + price2.icon}></i>
                              </div>
                            </div>
                          </div>

                          <hr className="my-4 text-muted" />
                          <div>
                            <ul className="list-unstyled text-muted vstack gap-3">
                              {(price2.points || []).map((point, key) => (
                                <li key={key}>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">{point}</div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div className="mt-3 pt-2">
                            <Link
                              to="/contact-us"
                              className={`btn btn-success w-100`}
                            >
                              Get Quote
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <div>
              <Detailtext
                workingText={
                  "How can our 2D Animation services elevate the visual appeal and storytelling of your content?"
                }
                workingPoints={animationServices}
                benefitText={
                  "How does our professional audio editing enhance the quality and clarity of your audio content, contributing to a more polished and professional final product?"
                }
              />

              <p className="reasonable_mrgn">
                At Softorithm, we take your audio experience to the next level
                through a range of cutting-edge audio editing services. Here's
                why you should choose us:{" "}
              </p>
              <Detailtext
                workingPoints={audioServices}
                benefitText={
                  "How can Softorithm's video editing services enhance the overall quality and appeal of your visual content?"
                }
              />
              <p className="reasonable_mrgn">
                Are you ready to transform your visual content into captivating
                masterpieces? Softorithm is your go-to destination for top-notch
                video editing services. Here's why you should entrust us with
                your vision:
              </p>
              <Detailtext workingPoints={videoCreationServices} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Animations;
