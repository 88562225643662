import { USER_LOGIN } from "../../../helpers/url_helper";
import { APIClient } from "../../../helpers/api_helper";
const api = new APIClient();


export const login = async (email, password) => {
  try {
    const response = await api.create(
      `${process.env.REACT_APP_USER_URL}${USER_LOGIN}`,
      {
        email,
        password,
      }
    );

    return response;
  } catch (error) {
    // Handle specific error response from the API
    if (error.response) {
      // You can access status code, headers, and data from error.response
      return error.response.data;
    } else {
      // Handle network errors, timeouts, etc.
      throw error; // Throw the original error for more general errors
    }
  }
};

