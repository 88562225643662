import React, { useEffect, useState } from "react";
import {
  Input,
  Label,
  Form,
  FormFeedback,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import MetaData from "../../components/MetaData";

import "../sass/guest_post.scss";
import GuestPostFaqs from "./GuestPostFaqs";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { RegisterUser, resetRegisterFlag } from "../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/withRouter";

const Guest_post = (props) => {
  const dispatch = useDispatch();

  const { user, errorMsg, loading, error } = useSelector((state) => ({
    user: state.Register.user,
    errorMsg: state.Register.errorMsg,
    loading: state.Register.isLoading,
    error: state.Register.error,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      fullName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      fullName: Yup.string().required("Please Enter Your Full Name"),
      password: Yup.string()
        // .required("Please Enter Password")
        .min(6, "Password must be at least 6 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{6,}$/,
          "Password must have at least one lowercase, one uppercase, one digit, and one non-alphanumeric character"
        ),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Confirm Password Isn't Match"
        ),
      }),
    }),
    onSubmit: (values) => {
      dispatch(RegisterUser(values, props.router.navigate));
    },
  });
  console.log(error);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetRegisterFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  return (
    <>
      <MetaData metaTitle={"Guest Post"} metaDescription={"Guest Post"} />
      {/* <AnimatePage> */}
      <div className="page_body">
        <div className="container mt-5">
          <div className="guest_post">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="about_rgt_cont">
                  <h2 className="text-center">Blog Guest Post</h2>
                </div>
              </div>
            </div>

            <div className="row g-3">
              <Col lg="8">
                <div className="d-flex align-items-center mb-3">
                  <div className="flex-shrink-0 me-1">
                    <i className="ri-question-line fs-24 align-middle text-info me-1"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5
                      className="fs-16 mb-0 fw-semibold"
                      title="Frequently Asked Questions"
                    >
                      FAQs
                    </h5>
                  </div>
                </div>
                <GuestPostFaqs />
              </Col>
              <Col lg="4" className="">
                <Card>
                  <CardHeader className="border-0 bg-success text-white">
                    <Row className="g-4 align-items-center">
                      <div className="">
                        <div className=" gap-2">
                          <h5 className="mb-0">Register Yourself</h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      {error && error ? (
                        <Alert color="danger">
                          <div>{errorMsg}</div>
                        </Alert>
                      ) : null}
                      <div className="mb-3">
                        <Label htmlFor="fullName" className="form-label mb-0">
                          Full Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="fullName"
                          type="text"
                          placeholder="Enter Full Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.fullName || ""}
                          invalid={
                            validation.touched.fullName &&
                            validation.errors.fullName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.fullName &&
                        validation.errors.fullName ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.fullName}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="useremail" className="form-label mb-0">
                          Email <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label
                          htmlFor="userpassword"
                          className="form-label mb-0"
                        >
                          Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.password}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label
                          htmlFor="confirmPassword"
                          className="form-label mb-0"
                        >
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.confirmPassword}</div>
                          </FormFeedback>
                        ) : null}
                        <div className="mt-2">
                          Already have an account ?{" "}
                          <Link to="/login" className="r_login_lnk">
                            Login
                          </Link>
                        </div>
                      </div>

                      <div className="submit_btn mt-4">
                        <button
                          className="btn w-100 mb-3 submit_btn"
                          type="submit"
                          disabled={error ? null : loading ? true : false}
                        >
                          {error ? null : loading ? (
                            <Spinner size="sm" className="me-2">
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          ) : null}
                          Register
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </div>
      </div>
      {/* </AnimatePage> */}
    </>
  );
};

export default withRouter(Guest_post);
