import React, { useState, useEffect, useMemo } from "react";
//Owl Carousel Libraries and Module
import "../sass/home.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import TextSlicer from "../../components/TextSlicer";
import MetaData from "../../components/MetaData";

const description = `If you're seeking online tools for converting media files between different formats, a variety of free options are available to assist you. These tools enable the conversion of audio files, video files, images, and more without the need to download any software. 

`;

const description2 = `Our curated list includes the following tools: Image Compressor, JPG to PNG, PNG to JPG, PNG to PDF, PDF to PNG, PDF to JPG, PDF to WORD, MP4 to MP3, MP3 to MP4, PNG to GIF, JPG to GIF, Background Remover, Image Resizer, Compare Text, Remove Extra Space, Remove Line Break, Add Line Break, Remove Duplicate Lines, Replace Character, Remove Character, Text Counter, Number Extractor, Phone No. Extractor, Text Transform, Paragraphs To Single Line, Line Breaks to Paragraphs, Contract Policy Generator, QR Code Generator, Password Generator, Gradient Generator, Text Generator, HTML Color Generator, Color Mixer, Number Generator, Privacy Generator, Age Calculator, Percentage Calculator, Average Calculator, Sales Tax Calculator, Margin Calculator, GST Calculator, Loan Calculator, CPM Calculator, Discount Calculator, PayPal Fee Calculator, and Image Editor.`;
// import AnimatePage from "../../components/AnimatePage";

const Home = () => {
  const navigate = useNavigate();
  const [showDiv, setShowDiv] = useState(false);
  const [items, setItems] = useState(4);
  const handleResize = () => {
    // Determine the number of items to show based on the screen width
    let itemsValue;
    if (window.innerWidth <= 992) {
      itemsValue = 1;
    } else if (window.innerWidth <= 1200) {
      itemsValue = 2;
    } else if (window.innerWidth <= 1400) {
      itemsValue = 3;
    } else {
      itemsValue = 4;
    }

    setItems(itemsValue);
  };
  //Owl Carousel Settings
  const options = useMemo(
    () => ({
      loop: false,
      margin: 30,
      responsiveClass: true,
      dots: false,
      nav: true,
      rewind: true,
      autoplay: false,
      smartSpeed: 700,
      autoplayTimeout: 7000,
      responsive: {
        0: {
          items: 1,
        },
        992: {
          items: 2,
        },
        1200: {
          items: 3,
        },
        1400: {
          items: 4,
        },
      },
    }),
    []
  );
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    
    setShowDiv(true); 
  }, []);

  return (
    <>
      <MetaData
        metaTitle={"Home"}
        metaDescription={
          "If you're seeking online tools for converting media files between different formats, a variety of free options are available to assist you."
        }
      />
      <div className="pg_bdy home_page">
        <div className="hero_slider">
          {/* <AnimatePage> */}
          <div className="container">
            <div className="pg_title ">
              <h2>
                Free
                <span className="mx-2">Online</span>
                {/* <ToolsText/> */}
                Tools
              </h2>
              {showDiv && <TextSlicer text={description} />}
              
            </div>

            <OwlCarousel className="owl-theme" items={items} {...options}>
              <div
                className="app_tools_item"
                onClick={() => {
                  navigate("/tools/compare-text");
                }}
              >
                <span className="itms_count">99+ tools</span>

                <div className="app_tools_itm_icn">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="70.000000pt"
                    height="70.000000pt"
                    viewBox="0 0 70.000000 70.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M250 650 c-11 -11 -20 -24 -20 -30 0 -5 -11 -10 -25 -10 -22 0 -25
                                                  -4 -25 -35 l0 -35 -55 0 -55 0 0 -255 0 -255 101 0 c76 0 100 3 97 13 -3 8
                                                  -32 13 -86 15 l-82 3 0 224 0 225 39 0 c37 0 40 -2 43 -31 l3 -30 123 -2 122
                                                  -2 0 33 c0 31 2 32 40 32 l40 0 0 -60 c0 -47 3 -60 15 -60 12 0 15 15 15 75
                                                  l0 75 -55 0 -55 0 0 35 c0 31 -3 35 -25 35 -15 0 -25 6 -25 14 0 43 -95 61
                                                  -130 26z m92 -27 c10 -9 18 -22 18 -30 0 -7 9 -13 21 -13 18 0 20 -5 17 -52
                                                  l-3 -53 -90 0 -90 0 -3 53 c-3 50 -2 52 22 52 18 0 26 5 26 18 0 41 48 56 82
                                                  25z"
                      ></path>
                      <path
                        d="M180 350 c0 -20 5 -20 128 -18 105 3 127 6 127 18 0 12 -22 15 -127
                                                  18 -123 2 -128 2 -128 -18z"
                      ></path>
                      <path
                        d="M437 222 c-108 -108 -117 -120 -117 -155 0 -37 0 -37 43 -37 40 0 47
                                                  5 160 117 64 64 116 117 114 117 -1 1 -20 18 -42 38 l-41 38 -117 -118z m58
                                                  -62 c-68 -68 -107 -100 -122 -100 -43 0 -24 34 79 137 103 103 103 103 123 83
                                                  20 -20 20 -20 -80 -120z"
                      ></path>
                      <path
                        d="M177 274 c-17 -17 13 -24 109 -24 86 0 104 3 104 15 0 12 -18 15
                                                  -103 15 -57 0 -107 -3 -110 -6z"
                      ></path>
                      <path
                        d="M180 175 c0 -13 11 -15 63 -13 34 2 62 8 62 13 0 6 -28 11 -62 13
                                                  -52 2 -63 0 -63 -13z"
                      ></path>
                      <path d="M507 62 c-23 -25 -21 -32 8 -32 20 0 25 5 25 25 0 30 -11 32 -33 7z"></path>
                    </g>
                  </svg>

                  <h4>Text editer</h4>
                </div>

                <div className="app_tools_itm_cont">
                  <p>
                    Solve Your Text Problems
                    <span>
                      <i className="ri-arrow-right-line"></i>
                    </span>
                  </p>

                  <div className="featured_itm">
                    <h5>Featured Tool :</h5>

                    <span>Compare Text</span>
                  </div>
                </div>
              </div>

              <div
                className="app_tools_item"
                onClick={() => {
                  navigate("/tools/image-compress");
                }}
              >
                <span className="itms_count">99+ tools</span>

                <div className="app_tools_itm_icn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    width="70.000000pt"
                    height="70.000000pt"
                    viewBox="0 0 70.000000 70.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path d="M245 632 c-32 -11 -51 -24 -53 -35 -4 -17 -1 -17 27 3 22 17 48 24 103 28 73 4 158 -11 158 -28 0 -5 -7 -7 -15 -4 -8 4 -15 1 -15 -6 0 -7 12 -10 32 -8 27 3 32 7 33 31 0 16 -3 31 -7 33 -4 3 -8 -2 -8 -10 0 -9 -1 -16 -2 -16 -2 0 -25 7 -52 15 -64 19 -139 18 -201 -3z"></path>
                      <path d="M50 511 c-5 -11 -10 -85 -10 -170 0 -186 -4 -181 145 -181 146 0 145 -1 145 158 l0 126 -42 43 -42 43 -93 0 c-81 0 -94 -2 -103 -19z m178 -23 c4 -30 35 -58 64 -58 16 0 18 -11 18 -113 0 -75 -4 -117 -12 -125 -16 -16 -210 -16 -226 0 -17 17 -17 299 0 316 9 9 37 12 83 10 68 -3 70 -4 73 -30z m62 -33 c0 -3 -7 -5 -15 -5 -15 0 -38 34 -29 43 6 5 44 -28 44 -38z"></path>
                      <path d="M105 440 c3 -5 17 -10 31 -10 13 0 24 5 24 10 0 6 -14 10 -31 10 -17 0 -28 -4 -24 -10z"></path>
                      <path d="M105 380 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11 0 -17 -4 -14 -10z"></path>
                      <path d="M170 380 c0 -5 20 -10 44 -10 25 0 48 5 51 10 4 6 -13 10 -44 10 -28 0 -51 -4 -51 -10z"></path>
                      <path d="M105 320 c-4 -6 13 -10 44 -10 28 0 51 5 51 10 0 13 -87 13 -95 0z"></path>
                      <path d="M230 320 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10 -8 0 -14 -4 -14 -10z"></path>
                      <path d="M105 260 c-4 -6 7 -10 25 -10 18 0 29 4 25 10 -3 6 -15 10 -25 10 -10 0 -22 -4 -25 -10z"></path>
                      <path d="M195 260 c-4 -6 10 -10 35 -10 25 0 39 4 35 10 -3 6 -19 10 -35 10 -16 0 -32 -4 -35 -10z"></path>
                      <path d="M380 511 c-5 -11 -10 -84 -10 -165 0 -189 -2 -186 143 -186 148 0 147 -1 147 158 l0 126 -42 43 -42 43 -93 0 c-81 0 -94 -2 -103 -19z m178 -23 c4 -30 35 -58 64 -58 16 0 18 -11 18 -113 0 -75 -4 -117 -12 -125 -16 -16 -210 -16 -226 0 -17 17 -17 299 0 316 9 9 37 12 83 10 68 -3 70 -4 73 -30z m59 -34 c-12 -13 -37 6 -37 28 l0 22 22 -21 c12 -13 19 -25 15 -29z"></path>
                      <path d="M550 350 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"></path>
                      <path d="M191 83 c0 -31 2 -35 11 -22 9 14 16 14 47 3 43 -16 143 -18 190 -4 44 12 83 38 76 50 -3 6 -20 0 -36 -12 -37 -28 -150 -37 -219 -18 -40 11 -43 13 -22 21 32 12 27 19 -13 19 -34 0 -35 -1 -34 -37z"></path>
                    </g>
                  </svg>

                  <h4>Converters</h4>
                </div>

                <div className="app_tools_itm_cont">
                  <p>
                    Solve Your Converting Problems
                    <span>
                      <i className="ri-arrow-right-line"></i>
                    </span>
                  </p>

                  <div className="featured_itm">
                    <h5>Featured Tool :</h5>

                    <span>Image Compressor</span>
                  </div>
                </div>
              </div>

              <div
                className="app_tools_item"
                onClick={() => {
                  navigate("/tools/qr-code-generator");
                }}
              >
                <span className="itms_count">99+ tools</span>

                <div className="app_tools_itm_icn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    width="70.000000pt"
                    height="70.000000pt"
                    viewBox="0 0 70.000000 70.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path d="M67 643 c-11 -10 -8 -153 2 -153 5 0 11 10 13 23 3 22 3 22 221 25 139 1 216 6 212 12 -4 6 -91 10 -221 10 l-214 0 0 35 0 35 280 0 280 0 0 -35 c0 -28 -4 -35 -20 -35 -11 0 -20 -4 -20 -10 0 -5 9 -10 20 -10 19 0 20 -7 20 -112 l0 -111 -25 23 c-22 21 -29 22 -46 12 -24 -15 -39 -8 -39 19 0 16 -7 19 -40 19 -36 0 -40 -2 -40 -25 0 -28 -11 -31 -40 -13 -17 11 -23 9 -42 -10 -19 -19 -21 -25 -10 -42 18 -29 15 -40 -12 -40 -18 0 -25 -6 -28 -22 -3 -22 -6 -23 -118 -23 l-115 0 -3 90 c-4 128 -22 134 -22 8 0 -68 4 -104 13 -111 6 -5 71 -12 144 -14 142 -3 164 -11 142 -46 -12 -18 -10 -24 11 -47 21 -23 25 -24 46 -11 24 17 34 12 34 -16 0 -14 8 -18 40 -18 33 0 40 3 40 19 0 28 18 36 39 17 16 -15 19 -14 44 12 25 26 26 29 11 44 -19 19 -11 38 17 38 16 0 19 7 19 40 0 32 -4 40 -18 40 -10 0 -22 3 -25 7 -9 9 23 44 35 37 5 -3 7 68 6 168 l-3 173 -291 3 c-160 1 -293 -1 -297 -5z m443 -293 c0 -23 43 -38 64 -21 20 17 43 -13 26 -34 -16 -19 -3 -55 21 -55 12 0 19 -7 19 -20 0 -13 -7 -20 -20 -20 -24 0 -40 -48 -19 -60 10 -7 10 -11 0 -24 -10 -12 -17 -14 -27 -5 -21 17 -64 2 -64 -21 0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 19 0 24 -36 37 -55 21 -12 -10 -18 -10 -31 0 -13 12 -13 15 0 28 19 19 1 62 -26 62 -11 0 -18 7 -18 20 0 13 7 20 18 20 27 0 46 45 26 61 -11 9 -12 15 -3 25 9 11 15 11 25 3 21 -17 64 -2 64 21 0 13 7 20 20 20 13 0 20 -7 20 -20z"></path>
                      <path d="M445 265 c-31 -30 -32 -65 -2 -93 46 -44 117 -14 117 48 0 64 -70 91 -115 45z m79 -11 c31 -30 9 -84 -34 -84 -23 0 -50 26 -50 47 0 23 29 53 51 53 10 0 25 -7 33 -16z"></path>
                      <path d="M235 600 c-4 -6 28 -10 90 -10 62 0 94 4 90 10 -3 6 -44 10 -90 10 -46 0 -87 -4 -90 -10z"></path>
                      <path d="M550 550 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4 -4 -4 -10z"></path>
                      <path d="M130 400 l0 -80 85 0 85 0 0 80 0 80 -85 0 -85 0 0 -80z m150 0 l0 -60 -65 0 -65 0 0 60 0 60 65 0 65 0 0 -60z"></path>
                      <path d="M390 470 c0 -6 33 -10 80 -10 47 0 80 4 80 10 0 6 -33 10 -80 10 -47 0 -80 -4 -80 -10z"></path>
                      <path d="M60 450 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4 -4 -4 -10z"></path>
                      <path d="M355 420 c4 -6 53 -10 115 -10 62 0 111 4 115 10 4 6 -37 10 -115 10 -78 0 -119 -4 -115 -10z"></path>
                      <path d="M135 280 c-4 -6 12 -10 39 -10 25 0 46 5 46 10 0 13 -77 13 -85 0z"></path>
                    </g>
                  </svg>

                  <h4>Generators</h4>
                </div>

                <div className="app_tools_itm_cont">
                  <p>
                    Solve Your Generating Problems
                    <span>
                      <i className="ri-arrow-right-line"></i>
                    </span>
                  </p>

                  <div className="featured_itm">
                    <h5>Featured Tool :</h5>

                    <span>QR Code Generator</span>
                  </div>
                </div>
              </div>

              <div
                className="app_tools_item"
                onClick={() => {
                  navigate("/tools/age-calculator");
                }}
              >
                <span className="itms_count">99+ tools</span>

                <div className="app_tools_itm_icn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    width="70.000000pt"
                    height="70.000000pt"
                    viewBox="0 0 70.000000 70.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path d="M31 556 c-8 -9 -11 -75 -9 -217 l3 -204 270 0 270 0 5 48 c4 40 10 50 38 67 95 58 94 184 -3 241 -24 14 -35 28 -35 44 0 13 -7 26 -16 29 -9 3 -127 6 -264 6 -198 0 -250 -3 -259 -14z m482 -5 c40 -1 47 -4 47 -21 0 -11 -7 -20 -15 -20 -35 0 -96 -34 -115 -65 -25 -40 -26 -111 -4 -144 14 -19 14 -25 3 -32 -12 -7 -11 -9 1 -9 8 0 41 -8 73 -17 l58 -18 -3 -40 -3 -40 -254 -3 c-197 -2 -256 1 -263 10 -4 7 -8 95 -8 195 0 137 3 185 13 195 10 10 61 13 218 12 112 -1 225 -2 252 -3z m106 -86 c62 -53 62 -137 0 -190 -102 -85 -253 48 -180 159 41 61 126 76 180 31z"></path>
                      <path d="M62 520 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path>
                      <path d="M130 530 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10z"></path>
                      <path d="M225 530 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10 -14 0 -23 -4 -19 -10z"></path>
                      <path d="M320 530 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10 -14 0 -25 -4 -25 -10z"></path>
                      <path d="M420 530 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10 -14 0 -25 -4 -25 -10z"></path>
                      <path d="M240 395 c0 -41 2 -75 5 -75 9 0 105 72 105 78 -1 8 -93 72 -103 72 -4 0 -7 -34 -7 -75z m90 3 c0 -6 -62 -48 -71 -48 -2 0 -2 22 -1 49 l4 49 33 -22 c19 -11 34 -24 35 -28z"></path>
                      <path d="M62 440 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path>
                      <path d="M62 360 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path>
                      <path d="M62 280 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path>
                      <path d="M138 263 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"></path>
                      <path d="M233 263 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"></path>
                      <path d="M333 263 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"></path>
                      <path d="M160 201 c0 -6 -17 -12 -37 -14 l-38 -4 38 -1 c20 -1 37 -6 37 -12 0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 68 11 153 12 l147 2 -145 3 c-79 2 -149 8 -154 13 -13 13 -46 13 -46 1z m32 -16 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z"></path>
                      <path d="M504 470 c-35 -14 -64 -59 -64 -100 0 -72 68 -122 135 -100 40 13 75 59 75 98 0 39 -29 83 -65 98 -39 16 -51 16 -81 4z m88 -21 c11 -6 26 -26 34 -45 12 -29 12 -39 0 -68 -17 -40 -42 -56 -86 -56 -43 0 -90 47 -90 90 0 65 82 111 142 79z"></path>
                      <path d="M524 435 c-4 -8 -4 -29 -2 -45 4 -25 2 -29 -13 -25 -30 10 -32 -16 -3 -46 35 -36 43 -36 78 0 30 31 27 56 -5 46 -17 -6 -19 -1 -19 39 0 35 -4 46 -15 46 -9 0 -18 -7 -21 -15z m42 -94 c25 20 25 4 1 -19 l-23 -22 -25 27 c-13 16 -17 22 -9 16 25 -22 30 -15 32 45 l2 57 3 -58 c4 -50 6 -57 19 -46z"></path>
                    </g>
                  </svg>

                  <h4>Calculators</h4>
                </div>

                <div className="app_tools_itm_cont">
                  <p>
                    Solve Your Caculating Problems
                    <span>
                      <i className="ri-arrow-right-line"></i>
                    </span>
                  </p>

                  <div className="featured_itm">
                    <h5>Featured Tool :</h5>

                    <span>Age Calculator</span>
                  </div>
                </div>
              </div>
            </OwlCarousel>

            <div className="counter_wrap">
              <div className="counter">{"items " + items + " of  4"}</div>
            </div>

            {showDiv && (
              <div className="my-4 text-center ">
                <TextSlicer text={description2} />
              </div>
            )}
          </div>
          {/* </AnimatePage> */}
        </div>
      </div>
    </>
  );
};

export default Home;
