import React, { useState, useRef } from "react";
import {
  Form,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
  Spinner,
  Button,
} from "reactstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import { avatar3 } from "../../images";
import { Link } from "react-router-dom";
//SimpleBar
import SimpleBar from "simplebar-react";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { createNewComment, createNewReply } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import { useProfile } from "../../components/Hooks/UserHooks";
const CommentSection = ({
  item,
  comments,
  getAllCommentsLoading,
  replies,
  getAllRepliesLoading,
}) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();
  const [replyTextareas, setReplyTextareas] = useState({}); // Use an object to store the state for each comment
  const [capVal, setCapVal] = useState(null);
  const [curMessage, setcurMessage] = useState("");
  const captchaRef = useRef();
  const { isLoading } = useSelector((state) => ({
    isLoading: state.Comment.isLoading,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      fullName: "",
      comment: "",
      website: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      fullName: Yup.string().required("Please Enter Your Full Name"),
      comment: Yup.string().required("Please Enter Your Comment"),
    }),
    onSubmit: (values) => {
      //   console.log(values);
      const newComment = {
        id: 0,
        postId: item.id,
        fullName: values["fullName"],
        email: values["email"],
        comment: values["comment"],
        website: values["website"],
      };

      dispatch(createNewComment(newComment));
      validation.resetForm();
      setCapVal(null)
      captchaRef.current.reset();
    },
  });

  const addMessage = (commentId) => {
    const message = {
      id: 0,
      commentId,
      reply: curMessage,
      // createdDate: new Date(),
    };

    dispatch(createNewReply(message));

    setcurMessage("");
  };
  const handleReplyClick = (commentId) => {
    // Close all other reply textareas
    setReplyTextareas((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      return { ...newState, [commentId]: true };
    });

    // Clear the input text
    setcurMessage("");
  };

  return (
    <>
      <ToastContainer closeButton={false} />
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h6 className="card-title mb-0 flex-grow-1">Comments</h6>
        </CardHeader>

        <CardBody className="px-0">
          <SimpleBar style={{ height: "auto" }} className="px-3 mx-n3 mb-2">
            {!getAllCommentsLoading ? (
              <>
                {comments &&
                  comments?.map((item, key) => {
                    return (
                      <div className="d-flex mb-4" key={key}>
                        <div className="flex-shrink-0">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-light text-primary">
                              {item?.fullName.charAt(0)}
                            </span>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h5 className="fs-13">
                            {item?.fullName}
                            <small className="text-muted ms-2">
                              {item?.createdDate &&
                                new Date(item?.createdDate)
                                  .toISOString()
                                  .split("T")[0]}
                            </small>
                          </h5>
                          <p className="text-muted">{item?.comment}</p>

                          {userProfile && (
                            <span
                              className="badge text-muted bg-light "
                              onClick={() => handleReplyClick(item?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="mdi mdi-reply"></i> Reply
                            </span>
                          )}

                          {replyTextareas[item?.id] && (
                            <div className="mt-2">
                              {/* ... other reply input content ... */}
                              <Row className="g-0 align-items-center">
                                <div className="col">
                                  <input
                                    type="text"
                                    value={curMessage}
                                    onChange={(e) =>
                                      setcurMessage(e.target.value)
                                    }
                                    className="form-control chat-input bg-light border-light"
                                    id="chat-input"
                                    placeholder="Your Reply"
                                  />
                                </div>
                                <div className="col-auto">
                                  <div className="chat-input-links ms-2">
                                    <div className="links-list-item">
                                      <Button
                                        type="button"
                                        color="success"
                                        onClick={() => {
                                          addMessage(item?.id);
                                        }}
                                        className="chat-send waves-effect waves-light"
                                      >
                                        <i className="ri-send-plane-2-fill align-bottom"></i>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          )}

                          {/* // reply section */}
                          <div className="d-flex mt-4">
                            {replies?.map((reply, key) => {
                              return item?.id === reply?.commentId ? (
                                <div key={key}>
                                  {
                                    reply?.replies && reply?.replies?.length > 0
                                      ? reply.replies.map((data, idx) => (
                                          <div className="d-flex" key={idx}>
                                            <div className="flex-shrink-0">
                                              <div className="avatar-xs">
                                                <span className="avatar-title rounded-circle bg-light text-primary">
                                                  {data?.createdByName.charAt(
                                                    0
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                              <h5 className="fs-13">
                                                {data?.createdByName}
                                                <small className="text-muted ms-2">
                                                  {data?.createdDate &&
                                                    new Date(data?.createdDate)
                                                      .toISOString()
                                                      .split("T")[0]}
                                                </small>
                                              </h5>
                                              <p className="text-muted">
                                                {data?.reply}
                                              </p>
                                            </div>
                                          </div>
                                        ))
                                      : "" // <p>No replies available.</p>
                                  }
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <Loader />
            )}
          </SimpleBar>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();

              return false;
            }}
            className="needs-validation"
            action="#"
          >
            <Row className="g-3 mx-3">
              <Col xs={12}>
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label text-body"
                >
                  Leave a Comments
                </label>
                <textarea
                  name="comment"
                  className={`form-control bg-light border-light ${
                    validation.touched.comment && validation.errors.comment
                      ? "is-invalid"
                      : ""
                  }`}
                  id="comment"
                  rows="3"
                  placeholder="Enter your comment..."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.comment}
                />
                {/* Error message */}
                {validation.touched.comment && validation.errors.comment && (
                  <div className="invalid-feedback">
                    {validation.errors.comment}
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <div className="mb-3">
                  <Label htmlFor="fullName" className="form-label mb-0">
                    Full Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="fullName"
                    type="text"
                    placeholder="Enter Full Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fullName || ""}
                    invalid={
                      validation.touched.fullName && validation.errors.fullName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.fullName && validation.errors.fullName ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.fullName}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col xs={6}>
                <div className="mb-3">
                  <Label htmlFor="useremail" className="form-label mb-0">
                    Email <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter email address"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.email}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col xs={6}>
                <div className="mb-3">
                  <label
                    htmlFor="website"
                    className="form-label text-body mb-0"
                  >
                    Website
                  </label>
                  <Input
                    name="website"
                    type="text"
                    placeholder="Enter Website"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.website || ""}
                    invalid={
                      validation.touched.website && validation.errors.website
                        ? true
                        : false
                    }
                  />
                  {validation.touched.website && validation.errors.website && (
                    <div className="invalid-feedback">
                      {validation.errors.website}
                    </div>
                  )}
                </div>
                <ReCAPTCHA
                  sitekey={"6Lcsd5wiAAAAAE8qnFuuRafzvNi5v0OOZnrUEvgu"}
                  ref={captchaRef}
                  onChange={(val) => {
                    setCapVal(val);
                  }}
                />
              </Col>
              <Col xs={12} className="text-end">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!capVal || isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2">
                      Loading...
                    </Spinner>
                  ) : null}
                  Post Comment
                </button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default CommentSection;
