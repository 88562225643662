import React from 'react'
import {Helmet} from "react-helmet"
const MetaData = ({metaTitle,metaDescription}) => {
  return (
    <>
           <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
    </>
  )
}

export default MetaData
