import { toast } from "react-toastify";
import {
  ALL_POSTS,
  CREATE_POST,
  DELETE_POST,
  UPDATE_POST,
  ALL_RECENT_APPROVED_POSTS,
  GET_POST_BY_ID,
  GET_USER_POST_BY_ID,
  GET_ALL_CATEGORIES,
} from "../../helpers/url_helper";

import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const getPosts = async () => {
  try {
    const res = await api.get(`${process.env.REACT_APP_USER_URL}${ALL_POSTS}`);

    if (res.status) {
    } else {
      toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};

const createPost = async (obj) => {
  const id = toast.loading("Creating Post...", { autoClose: false });
  try {
    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${CREATE_POST}`,
      obj
    );

    if (res.status) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    return res;
  } catch (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const onDeletePost = async (obj) => {
  const id = toast.loading("Deleting Post...", { autoClose: false });
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${DELETE_POST}?id=${obj}`
    );

    if (res.status) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    return obj;
  } catch (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const updatePost = async (obj) => {
  const id = toast.loading("Updating Post...", { autoClose: false });
  try {
    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${UPDATE_POST}`,
      obj
    );

    if (res.status) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    return { obj };
  } catch (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const getPostById = async (obj, navigate) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${GET_POST_BY_ID}?slug=${obj}`
    );

    if (res.status) {
    } else {
      // toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};

const getRecentApprovedPosts = async () => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${ALL_RECENT_APPROVED_POSTS}`
    );

    if (res.status) {
    } else {
      toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};

const getUserPostsById = async (obj) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${GET_USER_POST_BY_ID}?userId=${obj}`
    );

    if (res.status) {
    } else {
      // toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};

const getCategories = async () => {
  try {
    const res = await api.get(`${process.env.REACT_APP_USER_URL}${GET_ALL_CATEGORIES}`);

    if (res.status) {
    } else {
      toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};

export const services = {
  getPosts,
  createPost,
  onDeletePost,
  updatePost,
  getPostById,
  getRecentApprovedPosts,
  getUserPostsById,
  getCategories
};
