import React, { useEffect, useState, useContext, useCallback } from "react";
import { AppContent, AppHeader } from "../components/index";
import { Container, Row, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import sidebarContext from "../context/sidebar/sidebarContext";

const DefaultLayout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  // to use sidebar using context
  const sidebarcontext = useContext(sidebarContext);
  /// de strusture
  const { sidebarstate } = sidebarcontext;

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const [showFooter, setShowFooter] = useState(true);
  useEffect(() => {
    // Check if the current route is home ("/")
    if (location.pathname.includes("/contact-us")) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={`static_page  ${windowWidth < 1024 ? `small_view ` : " "} ${
          sidebarstate ? "small_view_ham" : ""
        }`}
      >
        <AppHeader />
        <AppContent />
        {showFooter && (
          <footer className="py-4 mt-4 bg-dark position-relative services_footer">
            <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
            <Container>
              <Row className="align-items-center">
                <Col lg={8} className="text-center text-lg-start">
                  <div className="mb-3 mb-lg-0">
                    <h4 className="text-white mb-0 fw-semibold">
                      Power Up Your Business: Get Started Today
                    </h4>
                  </div>
                </Col>
                <Col lg={4} className="text-center text-lg-end">
                  <div className="connect_container">
                    <Link
                      to="/contact-us"
                      target="_blank"
                      className="btn convert_btn"
                    >
                      Connect With Us
                    </Link>
                    <ul className="social_icons mt-1">
                      <li>
                        <Link
                          to="instagram.com/softorithm"
                          className="icon_link"
                        >
                          <i className="ri-instagram-line instagram_icon"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="facebook.com/softorithm"
                          className="icon_link"
                        >
                          <i className="ri-facebook-box-fill facebook_icon"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.youtube.com/@softorithm"
                          className="icon_link"
                        >
                          <i className="ri-youtube-line youtube_icon"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.tiktok.com/@softorithm"
                          className="icon_link"
                        >
                          <i className="ri-tiktok-fill tiktok_icon"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        )}
      </div>
    </>
  );
};

export default DefaultLayout;
