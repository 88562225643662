import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { avatar3 } from "../../../images";

const ProfileDropdown = () => {
  const navigate = useNavigate();

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleClick = (item) => {
    // console.log(row);
    navigate(`/user-profile/`);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className=" header-item topbar-user "
      >
        <DropdownToggle tag="button" type="button" className="btn px-0">
          <span className="d-flex align-items-center">
            {/* <img className="rounded-circle header-profile-user" src={avatar3}
                    alt="Header Avatar" /> */}
            <i className="ri-account-circle-line"></i>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
           // href={"/user-profile"}
            style={{ color: "#212529" }}
            onClick={() => {
handleClick();
            }}
          >
            <i className="ri-account-circle-line  fs-16  me-2"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>

          <div className="dropdown-divider"></div>

          <DropdownItem
            href={process.env.PUBLIC_URL +"/logout"}
            style={{ color: "#212529" }}
          >
            <i className=" ri-logout-box-r-line fs-16  me-2"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
