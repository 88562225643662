import React from "react";
import { NavLink } from "react-router-dom";
const CalculatorsList = () => {

  return (
    <>
      <li>
        <NavLink to="/tools/age-calculator">Age Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/percentage-calculator">Percentage Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/average-calculator">Average Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/sales-tax-calculator">Salestax Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/margin-calculator">Margin Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/gst-calculator">Gst Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/loan-calculator">Loan Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/cpm-calculator">Cpm Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/discount-calculator">Discount Calculator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/paypal-fee-calculator">PayPalFee Calculator</NavLink>
      </li>
    </>
  );
};

export default CalculatorsList;
