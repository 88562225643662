import { toast } from "react-toastify";
import {
  ALL_USERS,
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
} from "../../helpers/url_helper";

import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const getUsers = async () => {
  try {
    const res = await api.get(`${process.env.REACT_APP_USER_URL}${ALL_USERS}`);

    if (res.status) {
    } else {
      toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};

const createUser = async (obj) => {
  const id = toast.loading("Creating User...", { autoClose: false });
  try {
    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${CREATE_USER}`,
      obj
    );

    if (res.status) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    return res;
  } catch (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const onDeleteUser = async (obj) => {
  const id = toast.loading("Deleting User...", { autoClose: false });
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${DELETE_USER}?userid=${obj}`
    );

    if (res.status) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    return obj;
  } catch (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const updateUser = async (obj) => {
  const id = toast.loading("Updating User...", { autoClose: false });
  try {
    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${UPDATE_USER}`,
      obj
    );

    if (res.status) {
      const existingAuthUser = JSON.parse(sessionStorage.getItem("authUser"));
      if (existingAuthUser && res.updatedUser) {
        // Create a new user object with the updated properties
        const newUser = {
          ...existingAuthUser.user,
          ...res.updatedUser,
        };

        // Update the existingAuthUser with the new user object
        existingAuthUser.user = newUser;

        // Store the updated authUser back in sessionStorage
        sessionStorage.setItem("authUser", JSON.stringify(existingAuthUser));
        // Reload the page after a delay of 3 seconds
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    return { obj };
  } catch (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

export const services = {
  getUsers,
  createUser,
  onDeleteUser,
  updateUser,
};
