import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  loginRequest,
  reset_login_flag,
} from "./reducer";


import { toast } from "react-toastify";
import { login } from "./service";

export const loginUser = (username, password, navigate) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const response = await login(username, password);

    if (response.status) {
      console.log(response);
      // Save user data to session storage
      sessionStorage?.setItem("authUser", JSON?.stringify(response));

      dispatch(loginSuccess(response));

      navigate("/");
    } else {
      dispatch(apiError(response.message)); // Dispatch an error action
      toast.error(response.message, { theme: "colored" });
    }
  } catch (error) {
    // Handle network errors or unexpected errors

    dispatch(apiError(error)); // Dispatch an error action
    toast.error(error, { theme: "colored" });
  }
};

export const resetLoginFlag = () => (dispatch) => {
  // Reset the login flag without asynchronous operations
  dispatch(reset_login_flag());
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authUser");
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies?.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }

    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

