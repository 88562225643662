import { USER_REGISTER } from "../../../helpers/url_helper";
import { APIClient } from "../../../helpers/api_helper";
const api = new APIClient();


export const register = async (values) => {
  try {
    const response = await api.create(
      `${process.env.REACT_APP_USER_URL}${USER_REGISTER}`,
      {
        id:"",
        userImg:"1",
        fullName:values.fullName,
        email:values.email,
        password:values.password,
        confirmPassword:values.confirmPassword
      }
    );

    return response;
  } catch (error) {
    // Handle specific error response from the API
    if (error.response) {
      // You can access status code, headers, and data from error.response
      return error.response.data;
    } else {
      // Handle network errors, timeouts, etc.
      throw error; // Throw the original error for more general errors
    }
  }
};