import { createSlice } from "@reduxjs/toolkit";
import {
  createNewComment,
  getAllCommentsByPostId,
  createNewReply,
  getAllRepliesByPostId,
} from "./thunk";

export const initialState = {
  comments: [],
  replies: [],
  getAllCommentsLoading: false,
  getAllRepliesLoading: false,
  createNewReplyLoading: false,
  error: "",
  isLoading: false,
};

const commentSlice = createSlice({
  name: "comment",
  initialState,
  extraReducers: (builder) => {
    builder
      // post by id
      .addCase(getAllCommentsByPostId.pending, (state, action) => {
        state.getAllCommentsLoading = true;
      })
      .addCase(getAllCommentsByPostId.fulfilled, (state, action) => {
        // const { obj } = action.payload;

        state.getAllCommentsLoading = false;
        state.comments = action.payload?.posts;
      })
      .addCase(getAllCommentsByPostId.rejected, (state, action) => {
        state.getAllCommentsLoading = false;
      })

      .addCase(createNewComment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewComment.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;

        if (action.payload && action.payload.comment) {
          state.comments = [...state.comments, action.payload?.comment];
          if (state.replies.length === 0) {
            // If state.replies is empty, initialize it with an array containing action.payload.reply
            state.replies = [{ commentId: action.payload?.comment.id, replies: [] }];
          }
       
        }
      })
      .addCase(createNewComment.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getAllRepliesByPostId.pending, (state, action) => {
        state.getAllRepliesLoading = true;
      })
      .addCase(getAllRepliesByPostId.fulfilled, (state, action) => {
        // const { obj } = action.payload;

        state.getAllRepliesLoading = false;
        state.replies = action.payload?.replys;
      })
      .addCase(getAllRepliesByPostId.rejected, (state, action) => {
        state.getAllRepliesLoading = false;
      })

      .addCase(createNewReply.pending, (state, action) => {
        state.createNewReplyLoading = true;
      })
      .addCase(createNewReply.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.createNewReplyLoading = false;

        if (action.payload && action.payload.reply) {
          if (state.replies.length === 0) {
            // If state.replies is empty, initialize it with an array containing action.payload.reply
            state.replies = [{ commentId: action.payload.reply.commentId, replies: [action.payload.reply] }];
          } else {
            // If state.replies is not empty, update the replies accordingly
            state.replies = state.replies.map((reply) =>
              reply.commentId.toString() === action.payload.reply.commentId.toString()
                ? { ...reply, replies: [action.payload.reply, ...reply.replies] }
                : reply
            );
          }

        }
        // state.replies = [...state.replies, action.payload?.reply];
        // console.log(state.replies)
      })
      .addCase(createNewReply.rejected, (state, action) => {
        state.createNewReplyLoading = false;
      });
  },
});

export default commentSlice.reducer;
