import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

export const createNewComment = createAsyncThunk(
  "comment/createNewComment",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createComment(obj);
    } catch (error) {}
  }
);

export const getAllCommentsByPostId = createAsyncThunk(
  "comment/getAllCommentsByPostId",
  async (obj) => {
    try {
      return await services.AllCommentsByPostId(obj);
    } catch (error) {}
  }
);


export const createNewReply = createAsyncThunk(
  "comment/createNewReply",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createReply(obj);
    } catch (error) {}
  }
);


export const getAllRepliesByPostId = createAsyncThunk(
  "comment/getAllRepliesByPostId",
  async (obj) => {
    try {
      return await services.AllRepliesByPostId(obj);
    } catch (error) {}
  }
);
