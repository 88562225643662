export const designPricing = [
  {
    id: 1,
    purpose: " ",
    type: "Graphic Designing",
    icon: "ri-book-fill",
    points: [
      "Business Logos",
      "Business promotion flyers",
      "Business branding (Stationery)",
      "Business branding (Garments)",
      "Business branding (Sports)",
      "Brochure branding",
      "Business cards",
      "Branding mockups",
      "Social media posts",
      "YouTube thumbnails",
      "Business stamps",
      "Branding banners",
      "Business advertisement",
      "Product packing",
    ],
  },
  {
    id: 2,
    purpose: " ",
    type: "UI/UX DESIGN",
    icon: "ri-medal-line",
    points: [
      "Conducting user research",
      "Creating user personas",
      "Determining information architecture",
      "Creating user flows and wireframes",
      "Prototyping and user testing",
      "Mobile & web Application designs",
      "Landing page designs for web",
    ],
  },
];

export const frontendPricing = [
  {
    id: 1,
    purpose: " ",
    type: "HTML",
    icon: "ri-book-fill",
    points: [
      "HTML5",
      "CSS3",
      "Bootstrap Latest Version",
      "jQuery",
      "JavaScript",
      "PSD to HTML",
      "Figma to HTML",
      "XD to HTML",
      "Fully Mobile Responsive",
    ],
  },
  {
    id: 2,
    purpose: " ",
    type: "Wordpress",
    icon: "ri-medal-line",
    points: [
      "WordPress Installation",
      "WordPress Website Design",
      "WordPress Theme Integration",
      "WordPress Website Design With Elementor",
      "WordPress E-Commerce Website",
      "WordPress Custom Design",
      "WordPress Website Migration",
      "WordPress Plugin Integration",
      "Fully Mobile Responsive",
    ],
  },
  {
    id: 3,
    purpose: " ",
    type: "React Js",
    icon: "ri-stack-line",
    points: [
      "React.js Fundamentals",
      "Advanced React Techniques",
      "React Router",
      "State Management with Redux",
      "Redux Toolkit in Depth",
      "UI/UX Design Principles",
      "Reactstrap and Bootstrap Integration",
      "Component Libraries and Styling",
      "Performance Optimization in React",
    ],
  },
];

export const digitalMarketing = [
  {
    id: 1,
    purpose: " ",
    type: "On Page SEO",
    icon: "ri-book-fill",
    points: [
      "Keyword Research      ",
      "Title Tag      ",
      "Meta Description      ",
      "URL Structure    ",
      "Header Tags     ",
      "Keyword Placement     ",
      "Content Quality      ",
      "Internal Linking        ",
      "External Linking      ",
      "Image Optimization    ",
      "Page Loading Speed     ",
      "Mobile Optimization        ",
      "Social Media Integration      ",
      "Schema Markup       ",
      "User Experience (UX)       ",
      "SSL Certificate       ",
      "Robots.txt    ",
      "XML Sitemap      ",
      "Google Analytics and Search Console     ",
      "Content Updates    ",
    ],
  },
  {
    id: 2,
    purpose: " ",
    type: "Off Page SEO",
    icon: "ri-medal-line",
    points: [
      "Backlink Building           ",
      "Social Media Presence           ",
      "Social Bookmarking         ",
      "Influencer Marketing         ",
      "Content Marketing         ",
      "Online Reviews         ",
      "Local SEO         ",
      "Forum Participation        ",
      "Q&A Platforms         ",
      "Video Marketing:       ",
      "Podcasting          ",
      "Brand Mentions          ",
      "Broken Link Building        ",
      "Competitor Analysis:          ",
      "Document Sharing           ",
      "Webinars and Events          ",
      "Networking          ",
      "Press Releases    ",
      "Co-Creation         ",
      "Monitor and Adjust    ",
    ],
  },
  {
    id: 3,
    purpose: " ",
    type: "SEM",
    icon: "ri-stack-line",
    points: [
      " Define Goals and Objectives",
      "Keyword Research",
      "Ad Copy Creation",
      "Landing Page Optimization",
      "Ad Extensions",
      "Ad Scheduling",
      "Geographic Targeting",
      "Budget Management",
      "Bid Strategy",
      "Ad Placement",
      "Ad Group Structure",
      "Negative Keywords",
      "Quality Score Improvement",
      "Ad Testing",
      "Conversion Tracking",
      "Remarketing",
      "Ad Campaign Monitoring",
      "Ad Policy Compliance",
      "Analytics Integration",
      "Reporting and Analysis",
    ],
  },
];

export const webPricing = [
  {
    id: 1,
    purpose: "For Enterprise",
    type: "Enterprise web apps",
    icon: "ri-book-fill",
    points: [
      "Project & task management systems",
      "ERP",
      "PLM, PIM software",
      "Collaboration software & intranets",
      "CRM",
      "Financial management systems",
      "Document management systems",
      "Learning management systems",
      "Knowledge management systems",
      "Custom enterprise web apps",
    ],
  },
  {
    id: 2,
    purpose: "For Business",
    type: "Web portals",
    icon: "ri-medal-line",
    points: [
      "Self-service portals",
      "Customer portals",
      "Vendor portals",
      "Partner portals",
      "Patient portals",
      "Employee portals",
      "eLearning portals",
      "Community portals",
      "Insurance portals",
      "Investor portals",
      "Government portals",
    ],
  },
  {
    id: 3,
    purpose: "For Health Care",
    type: "Web apps for healthcare",
    icon: "ri-stack-line",
    points: [
      "EHR/EMR",
      "Customer portals",
      "Patient portals",
      "Partner portals",
      "Telemedicine apps",
    ],
  },
];

export const animationPricing = [
  {
    id: 1,
    purpose: "",
    type: "Audio Editing",
    icon: "ri-book-fill",
    points: [
      "Noise reduction",
      "Audio enhancing",
      "Remove your audio noise",
      "Audio mastering",
      "Audio mixing",
      "Audio boosting",
    ],
  },
  {
    id: 2,
    purpose: "",
    type: "Video Editing",
    icon: "ri-medal-line",
    points: [
      "YouTube video creation",
      "Merge your camera footage clips",
      "Make your video clips attractive by using special effects",
      "Make digital menus for your restaurants",
      "Make intro videos for your business",
      "Reels / Shorts Creation",
    ],
  },
  {
    id: 3,
    purpose: "",
    type: "2D Animations",
    icon: "ri-stack-line",
    points: [
      "YouTube intro videos",
      "Business logo animations",
      "YouTube subscribe animations",
      "Branding videos for your business",
      "Custom 2D animations",
      "Reels / Shorts Creation",
    ],
  },
];
