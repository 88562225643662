import { combineReducers } from "redux";


// Authentication
import LoginReducer from "./auth/login/reducer";
import RegisterReducer from "./auth/register/reducer"
import UserReducer from "./users/reducer"


import PostReducer from "./posts/reducer";

import CommentReducer from "./messages/reducer"
// API Key

//Benchmark

const rootReducer = combineReducers({

  Login: LoginReducer,
  Register:RegisterReducer,
  Posts: PostReducer,
  User:UserReducer,
  Comment:CommentReducer

});

export default rootReducer;
