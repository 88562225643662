import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { digitalMarketing } from "../design/DummyData";
import "../../sass/design.scss";
import Detailtext from "../../../components/Detailtext";
import TextSlicer from "../../../components/TextSlicer";

const onPageSEOExpertise = [
  "Strategic On-Page SEO Expertise: Softorithm possesses a team of experts with a deep understanding of on-page SEO techniques. Their strategic approach focuses on optimizing website elements to improve search engine rankings effectively.",
  "Tailored Solutions for Business Scale: Softorithm recognizes the diverse needs of businesses, offering on-page SEO services tailored to the specific scale and requirements of each client, whether small, large, or multinational.",
  "Localized Optimization for Small Businesses: Small businesses benefit from Softorithm's emphasis on localized on-page SEO. The company optimizes website content to target local keywords, helping small enterprises enhance their visibility within their target markets.",
  "Global Optimization for Multinationals: For multinational businesses, Softorithm's on-page SEO strategies extend to global optimization. They optimize websites to target international audiences, considering multiple languages, cultural nuances, and regional search patterns.",
  "Content Optimization for Enhanced Relevance: Softorithm places a strong emphasis on content optimization. By optimizing website content for relevancy and quality, they help businesses establish authority in their industries and attract target audiences.",
  "User-Friendly Website Design: On-page SEO at Softorithm goes hand-in-hand with creating a user-friendly website design. This ensures a positive user experience, contributing to lower bounce rates and increased engagement, factors that search engines value.",
  "Technical SEO Audits and Fixes: Softorithm conducts thorough technical SEO audits to identify and address issues that may impact website performance. This includes addressing issues such as page speed, mobile responsiveness, and crawlability.",
  "Comprehensive Keyword Research: The company conducts comprehensive keyword research to identify relevant and high-performing keywords for each business. This strategic approach ensures that on-page content is optimized for terms that resonate with the target audience.",
  "Regular Monitoring and Adaptation: Softorithm understands the dynamic nature of search engine algorithms. They provide ongoing monitoring and adaptation of on-page SEO strategies to keep businesses aligned with evolving search engine criteria.",
  "Transparent Reporting and Analytics: Softorithm provides transparent reporting and analytics, giving businesses insight into the performance of on-page SEO efforts. Clients receive detailed reports that showcase improvements in search engine rankings, website traffic, and user engagement.",
  "Cost-Effective Solutions: Softorithm's on-page SEO services are designed to be cost-effective, making them accessible to businesses of all sizes. The investment in on-page SEO with Softorithm yields long-term benefits, contributing to sustained online visibility and success.",
];

const offPageSEOExpertise = [
  "Expertise in Off-Page SEO: Softorithm is equipped with a team of seasoned professionals who specialize in off-page SEO strategies. Their expertise covers a wide range of techniques to enhance a website's visibility and ranking on search engines.",
  "Tailored Strategies for Business Scale: Softorithm recognizes the diverse needs of businesses, whether small, large, or multinational. They offer customized off-page SEO strategies that align with the specific scale and requirements of each business, ensuring effective results.",
  "Global Reach for Multinational Businesses: For multinational businesses, Softorithm understands the importance of a global online presence. Their off-page SEO services are designed to target international audiences, improving visibility across various regions and languages.",
  "Local SEO Focus for Small Businesses: Small businesses benefit from Softorithm's emphasis on local SEO strategies. By optimizing for local search, they help small enterprises establish a strong online presence within their communities, attracting local customers.",
  "Brand Authority Building: Softorithm's off-page SEO services go beyond basic optimization. They focus on building brand authority through strategic link building, social media signals, and other techniques, establishing businesses as authoritative figures in their industries.",
  "Enhanced Online Reputation Management: Reputation management is crucial for businesses of all sizes. Softorithm's off-page SEO includes strategies to monitor and enhance online reputation, fostering positive brand perception and trust among customers.",
  "Compliance with Search Engine Guidelines: Softorithm ensures that their off-page SEO practices adhere to the latest search engine guidelines. This commitment to compliance helps businesses avoid penalties and maintain a strong online presence within search engine parameters.",
  "Continuous Monitoring and Adaptation: The dynamic nature of SEO requires continuous monitoring and adaptation. Softorithm provides ongoing analysis and adjustment of off-page SEO strategies to keep businesses competitive in evolving online landscapes.",
  "Measurable Results and Reporting: Softorithm employs tools and metrics to measure the effectiveness of their off-page SEO efforts. Businesses receive comprehensive reports that outline the impact of the strategies implemented, allowing for informed decision-making.",
  "Cost-Effective Solutions: Softorithm understands the budget constraints faced by businesses. Their off-page SEO services are designed to provide cost-effective solutions that deliver tangible results, making it an accessible and valuable investment for businesses of all sizes.",
];

const semServices = [
  "Comprehensive SEM Services: Softorithm extends its services to Search Engine Marketing, covering paid advertising campaigns on platforms like Google Ads. This allows businesses to complement organic efforts with targeted paid strategies.",
  "Customized Campaigns: Softorithm tailors SEM campaigns to align with the specific goals and target audience of each business, ensuring a personalized approach that maximizes ROI.",
  "Keyword Research and Ad Optimization: Softorithm conducts thorough keyword research for SEM campaigns, optimizing ads for relevant and high-performing keywords to maximize visibility and click-through rates.",
  "Budget-Friendly Solutions: Understanding budget constraints, Softorithm provides cost-effective SEM solutions, making paid advertising accessible to businesses of all sizes.",
  "Performance Analysis and Reporting: Softorithm offers detailed performance analysis and reporting for SEM campaigns, allowing businesses to track the effectiveness of their paid advertising efforts and make informed decisions.",
];

const summary = `In summary, small to large or multinational companies contact Softorithm for a comprehensive suite of SEO services (on-page and off-page) and SEM, leveraging the company's expertise, customization, and commitment to delivering measurable results.
`;

const DigitalMarketing = () => {
  return (
    <div className="design">
      <div className="page_body">
        <div className="container mt-5">
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center mb-4 pb-2 about_rgt_cont">
                <h4 className="fw-semibold fs-22">
                  Choose the Digital marketing Service that's right for you
                </h4>
                <TextSlicer
                  text={`Elevate your online presence with our comprehensive Digital Marketing services. Our expertise extends across On-Page SEO, ensuring your website is finely tuned for search engines. Off-Page SEO enhances your online footprint through strategic link building. With our cutting-edge SEM solutions, we amplify your visibility through targeted paid advertising. Trust us for a holistic approach to digital success, delivering excellence in On-Page SEO, Off-Page SEO, and SEM. Let us optimize your digital journey and drive results that matter.
              `}
                />
                {/* <p className="text-muted mb-4 fs-15">
                 </p> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl={9}>
              <Row className="justify-content-center">
                {(digitalMarketing || []).map((price2, key) => (
                  <Col md={6} lg={4} xl={4} key={key}>
                    <Card className="pricing-box ribbon-box right">
                      <CardBody className="p-4 m-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-1 fw-semibold">{price2.type}</h5>
                            <p className="text-muted mb-0">{price2.purpose}</p>
                          </div>
                          <div className="avatar-sm">
                            <div className="avatar-title bg-light rounded-circle text-primary">
                              <i className={"fs-20 " + price2.icon}></i>
                            </div>
                          </div>
                        </div>

                        <hr className="my-4 text-muted" />
                        <div>
                          <ul className="list-unstyled text-muted vstack gap-3">
                            {(price2.points || []).map((point, key) => (
                              <li key={key}>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 text-success me-1">
                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1">{point}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-3 pt-2">
                          <Link
                            to="/contact-us"
                            className={`btn btn-success w-100`}
                          >
                            Get Quote
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <div>
            <Detailtext
              workingText={
                "How does Softorithm conduct technical SEO audits, and why are they crucial for addressing website performance issues?"
              }
              workingPoints={onPageSEOExpertise}
              benefitText={
                "How can your business expect ongoing support and analysis from us to stay competitive in off-page SEO?"
              }
              benefitPoints={offPageSEOExpertise}
            />
            <Detailtext
              workingText={
                "How can our SEM services significantly improve your website's visibility on search engines?"
              }
              workingPoints={semServices}
            />

            <p className="reasonable_mrgn">{summary}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
