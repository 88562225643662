import React from "react";
// import ReactDOM from 'react-dom/client';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { persistReducer } from "redux-persist";
import App from "./App";
import storage from "redux-persist/lib/storage"; // You can choose your storage engine

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["Login"], // Only 'Login' state will be persisted
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

function addLinkToHead() {
  // Create a new link element
  const linkElement = document.createElement("link");
  linkElement.rel = "stylesheet";
  linkElement.href =
    "https://cdn.jsdelivr.net/npm/remixicon/fonts/remixicon.css";

  // Append the link element to the head of the document
  document.head.appendChild(linkElement);
}

window.addEventListener("load", () => {
  addLinkToHead();
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <App />
    </React.Fragment>
  </Provider>
);

// To GENERATE SITEMAP.XML
//const rootElement = document.getElementById("root");
// Use createRoot to create a root
//const root = createRoot(rootElement);
// Call the render or hydrate method on the root
// if (rootElement.hasChildNodes()) {
//   root.hydrate(<App />);
// } else {
//   root.render(<App />);
// }
