import React from "react";
import { NavLink } from "react-router-dom";
const ImageEditorList = () => {
  return (
    <>
      <li>
        <NavLink to="/tools/image-editor">Edit image</NavLink>
      </li>

      {/* <li>
        <NavLink to="/tools/graphiceditor">Graphic Editor</NavLink>
      </li> */}
    </>
  );
};

export default ImageEditorList;
